import React from "react";
import classNames from "classnames";
import "./styles.css";

interface InputProps {
    className?: string;
    title: string;
    placeholder?: string;
    type?: "number" | "text" | "datetime-local" | "time" | "date";
    value: string;
    onChange(val: string): void;
    onKeyPress?(e: React.KeyboardEvent<HTMLInputElement>): void;
    icon?: "email" | "date" | "text";
    iconText?: string;
    error?: boolean;
    disabled?: boolean;
}

const Input: React.FC<InputProps> = (props) => {
    const [isFocused, setIsFocused] = React.useState<boolean>(false);

    return (
        <div
            className={classNames(`input ${props.className}`, {
                "input--focused": isFocused,
                "input--error": props?.error,
            })}
        >
            {props.title && <div className="input__title">{props.title}</div>}
            <div className="input__wrap">
                <input
                    className="input__input"
                    type={props?.type}
                    name="name"
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                    onFocus={(e) => setIsFocused(true)}
                    onBlur={(e) => setIsFocused(false)}
                    onKeyPress={(e) => {
                        if (props.onKeyPress) {
                            props.onKeyPress(e);
                        }
                    }}
                    autoComplete="off"
                    disabled={props.disabled || false}
                />
                {props?.icon && props?.icon === "email" && <div className="input__email"></div>}
                {/* {props?.icon && props?.icon === "date" && <div className="input__date"></div>} */}
                {props?.icon && props?.icon === "text" && props?.iconText && (
                    <div className="input__icon-text">{props?.iconText}</div>
                )}
            </div>
        </div>
    );
};

Input.defaultProps = {
    className: "",
    error: false,
    type: "text",
    disabled: false,
};

export default Input;
