import { gql } from "@apollo/client";

export const AUTH_USER = gql`
    query authUser($accessToken: String!) {
        authUser(accessToken: $accessToken) {
            accessToken
            refreshToken
        }
    }
`;

export const AUTH_USER_DUEL = gql`
    query userDuelToken($duelId: String, $pass: String) {
        userDuelToken(duelId: $duelId, pass: $pass) {
            accessToken
            liveKitToken
        }
    }
`;

export const USER_INFO = gql`
    query userInfo {
        userInfo {
            id
            name
            lastname
            middlename
            email
            comment
            phone
            groupIds
            roles {
                id
                name
            }
            isActive
            code
            types {
                id
                name
            }
            createdAt
            subscribed
            communication
            notifications {
                email {
                    selected
                    verified
                }
                telegram {
                    selected
                    verified
                }
                viber {
                    selected
                    verified
                }
                whatsapp {
                    selected
                    verified
                }
            }
            notificationsTypes
            balance
            englishName
            englishLastname
            duelAccess
            permissions {
                accessUserPage
                accessAllGroups
                createGroup
                registerUser
                seeUserData
                editUserData
                editPayments
                editBalance
                giveFreeDuels
                editDateMembership
                editDateTraining
                seeTransactions
                setDuelTime
                setDuelType
                setTasks
                setAdmin
                organizeDuel
                broadcastYoutube
                createTask
                participateFree
                participateFreeJudge
                participateFreeMaster
                participateFreePlayer
                seeVideo
                seeVideoAllGroups
                seeVideoCompleted
                seeVideoNotCompleted
                adminChat
                createInstruction
                setInstruction
                duelTypes
                tasks
                instructions
                connectDuel
                createDuel
                periods {
                    weekDay
                    timeFrom
                    timeTo
                }
                allTime
                paymentMethods
            }
            instructions {
                id
                name
            }
        }
    }
`;

export const GROUPS = gql`
    query groups(
        $offset: Int
        $limit: Int
        $sort: String
        $name: String
        $admin: String
        $author: String
        $id: String
    ) {
        groups(offset: $offset, limit: $limit, sort: $sort, name: $name, admin: $admin, author: $author, id: $id) {
            groups {
                id
                name
                dateFrom
                dateTo
                isActive
                count
                tasks
                tasksFrom
                types
                typesFrom
                time {
                    periods {
                        weekDay
                        timeFrom
                        timeTo
                    }
                    timeFrom
                    allTime
                }
                emailInfo {
                    email
                    name
                }
                admins {
                    id
                    code
                }
                author {
                    id
                    code
                }
                instructions
            }
            total
        }
    }
`;

export const USER = gql`
    query user($id: ID!) {
        user(id: $id) {
            id
            name
            lastname
            middlename
            email
            phone
            comment
            groupIds
            roles {
                id
                name
            }
            isActive
            code
            types {
                id
                name
            }
            createdAt
            communication
            notifications {
                email {
                    selected
                    verified
                }
                telegram {
                    selected
                    verified
                }
                viber {
                    selected
                    verified
                }
                whatsapp {
                    selected
                    verified
                }
            }
            notificationsTypes
            subscribed
            additionalInfo {
                howKnew
                whyChoose
                city
                profession
                jobPosition
                industry
                media
                age
            }
            balance
            englishName
            englishLastname
            isGroupAdmin
            isGroupAuthor
            permissions {
                adminChat
            }
        }
    }
`;

export const USERS = gql`
    query users(
        $groupId: ID!
        $sort: String
        $name: String
        $lastname: String
        $middlename: String
        $email: String
        $code: String
        $filterByRole: String
        $filterByType: String
        $offset: Int
        $limit: Int
    ) {
        users(
            groupId: $groupId
            sort: $sort
            name: $name
            lastname: $lastname
            middlename: $middlename
            email: $email
            code: $code
            filterByRole: $filterByRole
            filterByType: $filterByType
            offset: $offset
            limit: $limit
        ) {
            users {
                id
                name
                lastname
                middlename
                email
                phone
                comment
                groupIds
                roles {
                    id
                    name
                }
                isActive
                code
                types {
                    id
                    name
                }
                createdAt
                communication
                notifications {
                    email {
                        selected
                        verified
                    }
                    telegram {
                        selected
                        verified
                    }
                    viber {
                        selected
                        verified
                    }
                    whatsapp {
                        selected
                        verified
                    }
                }
                notificationsTypes
                subscribed
                additionalInfo {
                    howKnew
                    whyChoose
                    city
                    profession
                    jobPosition
                    industry
                    media
                    age
                }
                balance
                englishName
                englishLastname
                permissions {
                    accessUserPage
                    adminChat
                }
                isInvited
                isGroupAdmin
                isGroupAuthor
                duelAccess
            }
            total
            typeFilters {
                name
                value
            }
            roleFilters {
                name
                value
            }
        }
    }
`;

export const TASK = gql`
    query task($id: ID!) {
        task(id: $id) {
            id
            type
            name
            description
            role1
            role2
            author {
                name
                id
            }
            rating
        }
    }
`;

export const TASKS = gql`
    query tasks(
        $duelType: String
        $sort: String
        $offset: Int
        $limit: Int
        $isActive: Boolean
        $rateFrom: Int
        $rateTo: Int
        $title: String
        $description: String
        $firstPhrase: String
        $role: String
        $author: String
    ) {
        tasks(
            duelType: $duelType
            sort: $sort
            offset: $offset
            limit: $limit
            isActive: $isActive
            rateFrom: $rateFrom
            rateTo: $rateTo
            title: $title
            description: $description
            firstPhrase: $firstPhrase
            role: $role
            author: $author
        ) {
            tasks {
                id
                isActive
                type
                name
                description
                role1
                role2
                sentence
                rating
                author {
                    id
                    name
                }
                groups
            }
            total
        }
    }
`;

export const TASKS_FOR_SELECT = gql`
    query tasksForSelect {
        tasksForSelect {
            id
            type
            name
            description
            role1
            role2
        }
    }
`;

export const DUEL_TASK = gql`
    query duelTask($duelId: ID!) {
        duel(id: $duelId) {
            type
            price
            task {
                id
                name
                description
                role1
                role2
                sentence
            }
        }
    }
`;

export const DUELS = gql`
    query duels($email: String, $sort: String, $dates: String, $offset: Int, $limit: Int) {
        duels(email: $email, sort: $sort, dates: $dates, offset: $offset, limit: $limit) {
            duels {
                id
                type
                price
                access
                users {
                    userId
                    code
                    role
                }
                date
                time
                status
                videoUrl
                task {
                    id
                    name
                    description
                    sentence
                    role1
                    role2
                }
                results {
                    player1 {
                        judge2
                        judge3
                        judge1
                    }
                    player2 {
                        judge1
                        judge2
                        judge3
                    }
                }
                agreements {
                    player1
                    player2
                    votes {
                        judge1
                        judge2
                        judge3
                    }
                }
                characters {
                    player1
                    player2
                }
                userRole
                userVotes
                result
                streamKey
                organizerId
                group {
                    id
                    name
                }
                name
            }
            total
        }
    }
`;

export const DUELS_RECONNECT = gql`
    query duelsForReconnect {
        duelsForReconnect {
            id
            type
            taskId
            taskName
            date
            name
            organizerId
            price
            access
            group {
                id
                name
            }
            role
        }
    }
`;

export const INSTRUCTION = gql`
    query instruction {
        instruction {
            menu {
                id
                name
            }
            availableHtml
            instructionsForEdit {
                id
                html
                name
                author {
                    id
                    code
                }
                displayFor {
                    forAll
                    permission
                    groups
                }
            }
        }
    }
`;

export const USER_STATISTICS = gql`
    query userStatistics($id: ID!) {
        userStatistics(id: $id) {
            negotiation {
                master {
                    points
                    total
                }
                player {
                    total
                    points
                    defeat
                    win
                }
                judge {
                    total
                    points
                }
            }
            express {
                master {
                    points
                    total
                }
                player {
                    total
                    points
                    defeat
                    win
                }
                judge {
                    total
                    points
                }
            }
            mini {
                master {
                    points
                    total
                }
                player {
                    total
                    points
                    defeat
                    win
                }
                judge {
                    total
                    points
                }
            }
            mini2 {
                master {
                    points
                    total
                }
                player {
                    total
                    points
                    defeat
                    win
                }
                judge {
                    total
                    points
                }
            }
            totalPoints
        }
    }
`;

export const USER_GROUPS = gql`
    query userGroups($userId: ID!) {
        userGroups(userId: $userId) {
            id
            name
            dateFrom
            dateTo
            isActive
        }
    }
`;

export const DUELS_INFO = gql`
    query duelsInfo {
        duelsInfo {
            id
            text1
            text2
            text3
            text4
        }
    }
`;

export const SETTINGS = gql`
    query settings {
        settings {
            id
            duelPrice {
                negotiation
                mini
                mini2
                express
            }
            training {
                months
                days
                diplomaPoint
                diplomaHonoursPoint
            }
            diplomaRu {
                generate
                title
                text1
                text2
                text3
                text4
            }
            diplomaEn {
                generate
                title
                text1
                text2
                text3
                text4
            }
        }
    }
`;

export const DUELS_PRICE = gql`
    query duelTypesPrice {
        duelTypesPrice {
            negotiation
            mini
            mini2
            express
        }
    }
`;

export const PURCHASE = gql`
    query purchase($userId: ID!) {
        purchase(userId: $userId) {
            id
            userId
            memberships {
                id
                payDate
                startDate
                endDate
                duration
                price
            }
            trainings {
                id
                payDate
                startDate
                endDate
                diplomaNumber
                diplomaUrl
                diplomaEnNumber
                diplomaEnUrl
                points
                isChecked
                price
                duration {
                    months
                    days
                }
            }
            isShowEnglishName
            freeDuels
        }
    }
`;

export const DIPLOMAS = gql`
    query diplomas($dateFrom: String!, $dateTo: String!, $name: String, $lastname: String) {
        diplomas(dateFrom: $dateFrom, dateTo: $dateTo, name: $name, lastname: $lastname) {
            number
            url
            userId
        }
    }
`;

export const TRANSACTIONS = gql`
    query transactions(
        $userId: ID
        $sort: String
        $dateFrom: String!
        $dateTo: String!
        $code: String
        $amountFrom: Int
        $amountTo: Int
        $comment: String
    ) {
        transactions(
            userId: $userId
            sort: $sort
            dateFrom: $dateFrom
            dateTo: $dateTo
            code: $code
            amountFrom: $amountFrom
            amountTo: $amountTo
            comment: $comment
        ) {
            id
            date
            amount
            type
            comment
            userId
            code
            email
            isCompleted
        }
    }
`;

export const ALLTYPES = gql`
    query allTypes {
        allTypes {
            id
            name
            duelTypes
            tasks
            periods {
                weekDay
                timeFrom
                timeTo
            }
            allTime
        }
    }
`;

export const ALLROLES = gql`
    query allRoles {
        allRoles {
            id
            name
        }
    }
`;

export const ALL_USERS_EMAILS_TO_COMPLAINT = gql`
    query allUsersEmailsToComplaint($userId: String!) {
        allUsersEmailsToComplaint(userId: $userId) {
            name
            email
        }
    }
`;
