import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { errorsVar, successErrorType, userVar } from "../../cache/cache";
import { useReactiveVar } from "@apollo/client";
import { useInviteInGroupMutation } from "../../types/graphql";

import "./styles.css";
import { USERS } from "../../graphql/queries";
import Input from "../../components/common/Input";
import { setError, validateEmail } from "../../misc/common";
import ErrorMessage from "../../components/common/ErrorMessage";

const Button = React.lazy(() => import("../../components/common/Button"));

interface LocationState {
    groupId: string;
    groupEmails: string[];
}

const AddUserInGroup: React.FC = () => {
    const [isEmail, setIsEmail] = React.useState<string>("");
    const [isBtnDisabled, setIsBtnDisabled] = React.useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const user = useReactiveVar(userVar);
    let errors = useReactiveVar(errorsVar);
    const locationState = location.state as LocationState;

    const [inviteInGroup] = useInviteInGroupMutation({
        onCompleted(data) {
            setIsBtnDisabled(false);
            navigate(location.pathname + location.search);
        },
        onError(error) {
            setIsBtnDisabled(false);
        },
        refetchQueries: () => {
            return [
                {
                    query: USERS,
                    variables: {
                        groupId: locationState.groupId || "",
                        sort: "codeAsc",
                        code: "",
                        email: "",
                        filterByRole: "",
                        filterByType: "",
                        lastname: "",
                        limit: 10,
                        middlename: "",
                        name: "",
                        offset: 0,
                    },
                },
            ];
        },
    });
    const inviteUser = () => {
        setIsBtnDisabled(true);
        errors = errorsVar([]);
        const newErrors: successErrorType[] = [];
        if (!validateEmail(isEmail)) {
            newErrors.push({
                place: "invite-user.email",
                message: "Email имеет неверный формат",
            });
        }
        if (locationState.groupEmails.includes(isEmail)) {
            newErrors.push({
                place: "invite-user.email",
                message: "Пользователь с таким email уже был добавлен в эту группу",
            });
        }
        if (newErrors.length === 0) {
            inviteInGroup({
                variables: {
                    email: isEmail,
                    groupId: locationState.groupId,
                },
            });
        } else {
            setIsBtnDisabled(false);
            errorsVar(setError(errors, newErrors));
        }
    };

    const allErrors = errors.filter((el) => el?.place?.startsWith("invite-user"));
    const emailError = errors.find((el) => el?.place === "invite-user.email");

    return (
        <div className="add-user-in-group">
            {user && (
                <>
                    <div className="add-user-in-group__title">Добавить в группу</div>
                    {allErrors.length > 0 && (
                        <ErrorMessage
                            className="add-user-in-group__error"
                            errors={allErrors}
                        />
                    )}
                    <div className="add-user-in-group__container">
                        <Input
                            title={"Email"}
                            value={isEmail}
                            onChange={(val) => setIsEmail(val)}
                            error={Boolean(emailError?.message)}
                        />
                        <Button
                            text="Добавить"
                            onClick={() => inviteUser()}
                            mode="burgundy"
                            disabled={isBtnDisabled}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default AddUserInGroup;
