import React from "react";
import * as ReactDOM from "react-dom/client";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { ApolloClient, ApolloProvider, createHttpLink, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { cache } from "./cache/cache";
import {
    getAccessToken,
    setAccessToken,
    removeAccessToken,
    getCookieValue,
    deleteCookie,
    setRefreshCookie,
} from "./misc/common";
import { IToken } from "./types/types";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import "./phone-input.css";

// if (["prod"].includes(process.env.REACT_APP_STAGE || "")) {
//   Sentry.init({
//     // TODO: move dsn outside from here
//     dsn: "https://a6e6b06fd6d844f3a1947117b4f0e1e5@o4504212844904448.ingest.sentry.io/4504212849819648",
//     integrations: [new BrowserTracing()],
//     environment: process.env.REACT_APP_STAGE,

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });
// }

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
    const token = getAccessToken();

    return {
        headers: {
            ...headers,
            authorization: token !== null ? token : "",
        },
    };
});

const refreshTokenLink = new TokenRefreshLink({
    isTokenValidOrUndefined: () => {
        const token = getAccessToken();
        // return false
        if (token !== null) {
            const decoded = jwtDecode<IToken>(token);
            return decoded?.exp > Date.now() / 1000 ? true : false;
        } else {
            return true;
        }
    },
    fetchAccessToken: () => {
        const accessToken = getAccessToken();

        const refreshToken = getCookieValue("refreshAuthToken");
        if (refreshToken) {
            deleteCookie("refreshAuthToken");
        }
        if (accessToken !== null) {
            return axios.post(`${process.env.REACT_APP_API_URL}/graphql`, {
                operationName: "refreshAuthUser",
                variables: { accessToken, refreshToken },
                query: "query refreshAuthUser($accessToken: String!, $refreshToken: String!) { refreshAuthUser(accessToken: $accessToken, refreshToken: $refreshToken) { accessToken, refreshToken } }",
            });
        }
        return new Promise(() => null);
    },
    handleFetch: (accessToken) => {
        setAccessToken(accessToken);
    },
    handleResponse: (operation, accessTokenField) => (resp) => {
        setRefreshCookie(resp.data.data.refreshAuthUser.refreshToken);
        return {
            // eslint-disable-next-line camelcase
            access_token: resp?.data?.data?.refreshAuthUser?.accessToken || undefined,
        };
    },
    handleError: (err) => {
        console.log("handleError", err);
        removeAccessToken();
        deleteCookie("refreshAuthToken");
        window.location.href = `${process.env.REACT_APP_AUTH_DOMAIN}?from=${process.env.REACT_APP_DOMAIN}`;
    },
});

const client = new ApolloClient({
    link: ApolloLink.from([refreshTokenLink, authLink, httpLink]),
    cache: cache,
});

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <React.Suspense fallback={<div className="preloader"></div>}>
                <App />
            </React.Suspense>
        </ApolloProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
