import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { userVar, errorsVar, successErrorType, duelsInfoVar } from "../../cache/cache";
import { UpdateDuelsInfoMutationVariables, useUpdateDuelsInfoMutation } from "../../types/graphql";
import { setError } from "../../misc/common";
import { DUELS_INFO } from "../../graphql/queries";

import "./styles.css";

const Textarea = React.lazy(() => import("../../components/common/Textarea"));
const Button = React.lazy(() => import("../../components/common/Button"));
const ErrorMessage = React.lazy(() => import("../../components/common/ErrorMessage"));

const UpdateDuelsInfo: React.FC = (duelsInfoId) => {
    const duelsInfo = useReactiveVar(duelsInfoVar);
    const [fields, setFields] = React.useState<UpdateDuelsInfoMutationVariables>({
        id: duelsInfo?.id || "",
        text1: duelsInfo?.text1 || "",
        text2: duelsInfo?.text2 || "",
        text3: duelsInfo?.text3 || "",
        text4: duelsInfo?.text4 || "",
    });

    const navigate = useNavigate();
    const location = useLocation();
    const user = useReactiveVar(userVar);
    const isAdmin = user?.roles?.map((r) => r.id)?.includes("admin");
    let errors = useReactiveVar(errorsVar);

    const [updateDuelsInfo] = useUpdateDuelsInfoMutation({
        onCompleted: (data) => {
            if (data?.updateDuelsInfo) {
                navigate(location.pathname + location.search);
            }
        },
        refetchQueries: () => {
            return [
                {
                    query: DUELS_INFO,
                },
            ];
        },
        onError: (e) => {
            console.log(e);
            const newErrors: successErrorType[] = [];
            newErrors.push({ place: "update-duelsInfo.system", message: e?.message });
            if (newErrors.length > 0) {
                errorsVar(setError(errors, newErrors));
            }
        },
    });

    React.useEffect(() => {
        if (!user || !isAdmin) {
            navigate("/", { replace: true });
        }
    }, [user, navigate, isAdmin]);

    const submit = () => {
        errors = errorsVar([]);

        const newErrors: successErrorType[] = [];

        if (newErrors.length === 0) {
            updateDuelsInfo({ variables: fields });
        } else {
            errorsVar(setError(errors, newErrors));
        }
    };
    const allErrors = errors.filter((el) => ["update-duelsInfo.system"].includes(el?.place || ""));

    return (
        <div className="update-duelsInfo">
            <div className="update-duelsInfo__title">Редактирование описания и временных промежутков</div>
            {allErrors.length > 0 && (
                <ErrorMessage
                    className="update-duelsInfo__error"
                    errors={allErrors}
                />
            )}
            <>
                <Textarea
                    id="update-duelsInfo-textarea"
                    className="update-duelsInfo__textarea"
                    title="Верхний текст (жирный)"
                    placeholder="Введите текст"
                    value={fields.text1 || ""}
                    onChange={(val) => setFields({ ...fields, text1: val })}
                />
                <Textarea
                    id="update-duelsInfo-textarea"
                    className="update-duelsInfo__textarea"
                    title="Верхний текст (обычный)"
                    placeholder="Введите текст"
                    value={fields.text2 || ""}
                    onChange={(val) => setFields({ ...fields, text2: val })}
                />
                <Textarea
                    id="update-duelsInfo-textarea"
                    className="update-duelsInfo__textarea"
                    title="Нижний текст (жирный)"
                    placeholder="Введите текст"
                    value={fields.text3 || ""}
                    onChange={(val) => setFields({ ...fields, text3: val })}
                />
                <Textarea
                    id="update-duelsInfo-textarea"
                    className="update-duelsInfo__textarea"
                    title="Нижний текст (обычный)"
                    placeholder="Введите текст"
                    value={fields.text4 || ""}
                    onChange={(val) => setFields({ ...fields, text4: val })}
                />
            </>
            <Button
                text="Изменить"
                mode="burgundy"
                onClick={() => submit()}
            />
        </div>
    );
};

export default UpdateDuelsInfo;
