import { InMemoryCache, makeVar } from "@apollo/client";
import {
    Duel,
    DuelsInfo,
    Task,
    UpdateDuelMutationVariables,
    UpdateTaskMutationVariables,
    UpdateUserMutationVariables,
    UpdateGroupMutationVariables,
    User,
} from "../types/graphql";
import { IMessenger, IStage } from "../types/types";

export interface successErrorType {
    place?: string;
    message: string;
}

export interface duelTimeouts {
    timeout: number;
    speechTimeout: number;
    disconnectTimeout: number;
}

export interface ITaskVoteVar {
    id: string;
}

export interface IEnglishNameVar {
    userId: string;
    englishName: string;
    englishLastname: string;
}

export interface IInstructionEditVar {
    id: string;
    name: string;
    html: string;
    displayForAll?: boolean;
    displayForPermission?: string;
}

export const successVar = makeVar<successErrorType | null>(null);
export const errorsVar = makeVar<successErrorType[]>([]);
export const userVar = makeVar<User | undefined | null>(undefined);
export const groupEditVar = makeVar<UpdateGroupMutationVariables | null>(null);
export const userEditVar = makeVar<UpdateUserMutationVariables | null>(null);
export const taskEditVar = makeVar<UpdateTaskMutationVariables | null>(null);
export const duelEditVar = makeVar<UpdateDuelMutationVariables | null>(null);
export const cancelVericationVar = makeVar<IMessenger[] | null>(null);
export const taskInfoVar = makeVar<Task | null>(null);
export const playerAnswerVar = makeVar<{ playerNumber: number; value: string } | null>(null);
export const duelInfoVar = makeVar<Duel | null>(null);
export const stagesCurrentTimerVar = makeVar<Partial<Record<IStage, number>> | null>(null);
export const duelTimeoutVar = makeVar<duelTimeouts>({
    timeout: 0,
    speechTimeout: 0,
    disconnectTimeout: 0,
});
export const taskVoteVar = makeVar<ITaskVoteVar | null>(null);
export const createDialogVar = makeVar<string | null>(null);
export const duelsInfoVar = makeVar<DuelsInfo | null>(null);
export const englishNameVar = makeVar<IEnglishNameVar | null>(null);
export const adminChatVar = makeVar<boolean | null>(null);
export const instructionEditVar = makeVar<IInstructionEditVar | null>(null);

export const cache: InMemoryCache = new InMemoryCache({
    typePolicies: {
        Event: {
            keyFields: ["date", "time"],
        },
    },
});
