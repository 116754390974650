import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import "./styles.css";

interface ICookiePolicyProps {}

const CookiePolicy: React.FC<ICookiePolicyProps> = () => {
    const navigate = useNavigate();
    const [hideModalCookies, setHideModalCookies] = useState<boolean>(false);
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 510.98px)" });

    const acceptCookies = () => {
        localStorage.setItem("cookiesAccepted", "cookiesAccepted");
        setHideModalCookies(true);
    };
    return (
        <div className={`cookiePolicy-mainContainer ${hideModalCookies ? "cookiePolicy--hide" : ""}`}>
            <div className="cookiesPolicy-mainContent">
                <div className="cookiePolicy-mainText">
                    Продолжая использование данного сайта, это означает, что Вы даете{" "}
                    <span
                        className="cookiePolicy__openModal"
                        onClick={() => navigate("#cookie-policy")}
                    >
                        согласие на использование нами cookie-файлов
                    </span>
                </div>
                <div className="cookiePolicy_btnsContainer">
                    {!isTabletOrMobile && (
                        <div
                            className="cookiePolicy__btn"
                            onClick={() => acceptCookies()}
                        >
                            Принять
                        </div>
                    )}
                    <div
                        onClick={() => acceptCookies()}
                        className="popup__close--blue"
                    />
                </div>
            </div>
            {isTabletOrMobile && (
                <div
                    className="cookiePolicy__btn"
                    onClick={() => acceptCookies()}
                >
                    Принять
                </div>
            )}
        </div>
    );
};

export default CookiePolicy;
