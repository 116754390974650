import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AdditionalInfo = {
  __typename?: 'AdditionalInfo';
  age?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  howKnew?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  jobPosition?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  whyChoose?: Maybe<Scalars['String']>;
};

export type AdditionalInfoInput = {
  age?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  howKnew?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  jobPosition?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<Scalars['String']>;
  profession?: InputMaybe<Scalars['String']>;
  whyChoose?: InputMaybe<Scalars['String']>;
};

export type AdminInfo = {
  __typename?: 'AdminInfo';
  code?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type AgreementsResult = {
  __typename?: 'AgreementsResult';
  player1?: Maybe<Scalars['String']>;
  player2?: Maybe<Scalars['String']>;
  votes?: Maybe<DuelVotes>;
};

export type AuthorInfo = {
  __typename?: 'AuthorInfo';
  code?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
};

export type AvailableTasksMap = {
  __typename?: 'AvailableTasksMap';
  express?: Maybe<Array<Scalars['String']>>;
  mini?: Maybe<Array<Scalars['String']>>;
  mini2?: Maybe<Array<Scalars['String']>>;
  negotiation?: Maybe<Array<Scalars['String']>>;
};

export type Details = {
  __typename?: 'Details';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Diploma = {
  __typename?: 'Diploma';
  number?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type DiplomaSettings = {
  __typename?: 'DiplomaSettings';
  generate: Scalars['Boolean'];
  text1: Scalars['String'];
  text2: Scalars['String'];
  text3: Scalars['String'];
  text4: Scalars['String'];
  title: Scalars['String'];
};

export type DiplomaSettingsInput = {
  generate: Scalars['Boolean'];
  text1: Scalars['String'];
  text2: Scalars['String'];
  text3: Scalars['String'];
  text4: Scalars['String'];
  title: Scalars['String'];
};

export type DisplayInfo = {
  __typename?: 'DisplayInfo';
  forAll?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  permission?: Maybe<Scalars['String']>;
};

export type Duel = {
  __typename?: 'Duel';
  access?: Maybe<Scalars['String']>;
  agreements?: Maybe<AgreementsResult>;
  characters?: Maybe<DuelCharacters>;
  date?: Maybe<Scalars['String']>;
  group?: Maybe<Group>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  organizerId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  result?: Maybe<Scalars['String']>;
  results?: Maybe<DuelResult>;
  status?: Maybe<Scalars['String']>;
  streamKey?: Maybe<Scalars['String']>;
  task?: Maybe<Task>;
  time?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userRole?: Maybe<Scalars['String']>;
  userVotes?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<DuelUser>>>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type DuelCharacters = {
  __typename?: 'DuelCharacters';
  player1?: Maybe<Scalars['ID']>;
  player2?: Maybe<Scalars['ID']>;
};

export type DuelCredentials = {
  __typename?: 'DuelCredentials';
  accessToken?: Maybe<Scalars['String']>;
  liveKitToken?: Maybe<Scalars['String']>;
};

export type DuelLobby = {
  __typename?: 'DuelLobby';
  access?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  group?: Maybe<Group>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizerId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['ID']>;
  taskName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type DuelPrice = {
  __typename?: 'DuelPrice';
  express: Scalars['Float'];
  mini: Scalars['Float'];
  mini2: Scalars['Float'];
  negotiation: Scalars['Float'];
};

export type DuelPriceInput = {
  express: Scalars['Float'];
  mini: Scalars['Float'];
  mini2: Scalars['Float'];
  negotiation: Scalars['Float'];
};

export type DuelResult = {
  __typename?: 'DuelResult';
  player1?: Maybe<DuelVotes>;
  player2?: Maybe<DuelVotes>;
};

export type DuelUser = {
  __typename?: 'DuelUser';
  code?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type DuelVotes = {
  __typename?: 'DuelVotes';
  judge1?: Maybe<Scalars['Int']>;
  judge2?: Maybe<Scalars['Int']>;
  judge3?: Maybe<Scalars['Int']>;
};

export type Duels = {
  __typename?: 'Duels';
  duels?: Maybe<Array<Maybe<Duel>>>;
  total?: Maybe<Scalars['Int']>;
};

export type DuelsInfo = {
  __typename?: 'DuelsInfo';
  id: Scalars['ID'];
  text1?: Maybe<Scalars['String']>;
  text2?: Maybe<Scalars['String']>;
  text3?: Maybe<Scalars['String']>;
  text4?: Maybe<Scalars['String']>;
};

export type EmailInfo = {
  __typename?: 'EmailInfo';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EmailInfoInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Group = {
  __typename?: 'Group';
  admins?: Maybe<Array<Maybe<AdminInfo>>>;
  allAvailableDuelTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  allAvailablePeriods?: Maybe<Array<Maybe<Period>>>;
  allAvailableTasks?: Maybe<Array<Maybe<Scalars['String']>>>;
  author?: Maybe<AdminInfo>;
  availableAllTime?: Maybe<Scalars['Boolean']>;
  count?: Maybe<Scalars['Int']>;
  dateFrom?: Maybe<Scalars['Int']>;
  dateTo?: Maybe<Scalars['Int']>;
  emailInfo?: Maybe<EmailInfo>;
  id?: Maybe<Scalars['ID']>;
  instructions?: Maybe<Array<Maybe<Scalars['String']>>>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tasks?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tasksFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  time?: Maybe<TimeInfo>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  typesFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GroupEmailComplaint = {
  __typename?: 'GroupEmailComplaint';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Groups = {
  __typename?: 'Groups';
  groups?: Maybe<Array<Maybe<Group>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Instruction = {
  __typename?: 'Instruction';
  availableHtml?: Maybe<Scalars['String']>;
  instructionsForEdit?: Maybe<Array<Maybe<InstructionInfo>>>;
  menu?: Maybe<Array<Maybe<MenuItem>>>;
};

export type InstructionInfo = {
  __typename?: 'InstructionInfo';
  author: AuthorInfo;
  displayFor?: Maybe<DisplayInfo>;
  html: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Membership = {
  __typename?: 'Membership';
  duration?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  payDate?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MessageInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  addDiploma?: Maybe<OkMessage>;
  addGroup?: Maybe<OkMessage>;
  addInstruction?: Maybe<OkMessage>;
  addMembership?: Maybe<OkMessage>;
  addTask?: Maybe<Task>;
  addTraining?: Maybe<OkMessage>;
  addUser?: Maybe<OkMessage>;
  cancelMessengerVerification?: Maybe<OkMessage>;
  changeBalance?: Maybe<OkMessage>;
  changeFreeDuels?: Maybe<OkMessage>;
  changeMembershipEndDate?: Maybe<OkMessage>;
  changeTrainingStartDate?: Maybe<OkMessage>;
  deleteAdmin?: Maybe<OkMessage>;
  deleteDuel?: Maybe<OkMessage>;
  deleteGroup?: Maybe<OkMessage>;
  deleteInstruction?: Maybe<OkMessage>;
  deleteTask?: Maybe<OkMessage>;
  deleteUserFromGroup?: Maybe<OkMessage>;
  importUsers?: Maybe<OkMessage>;
  inviteInGroup?: Maybe<OkMessage>;
  organizeDuel?: Maybe<OkMessage>;
  sendComplaint?: Maybe<OkMessage>;
  sendComplaintFromDuel?: Maybe<OkMessage>;
  sendQuestion?: Maybe<OkMessage>;
  setAdmin?: Maybe<OkMessage>;
  updateDuel?: Maybe<OkMessage>;
  updateDuelsInfo?: Maybe<DuelsInfo>;
  updateEnglishName?: Maybe<OkMessage>;
  updateGroup?: Maybe<OkMessage>;
  updateInstruction?: Maybe<OkMessage>;
  updateSettings?: Maybe<Settings>;
  updateTask?: Maybe<Task>;
  updateUser?: Maybe<OkMessage>;
  voteTask?: Maybe<VoteResult>;
};


export type MutationAddDiplomaArgs = {
  trainingId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAddGroupArgs = {
  dateFrom: Scalars['Int'];
  dateTo: Scalars['Int'];
  emailInfo?: InputMaybe<EmailInfoInput>;
  instructions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tasks?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tasksFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  time?: InputMaybe<TimeInfoInput>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  typesFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationAddInstructionArgs = {
  displayForAll?: InputMaybe<Scalars['Boolean']>;
  displayForPermission?: InputMaybe<Scalars['String']>;
  html: Scalars['String'];
  name: Scalars['String'];
};


export type MutationAddMembershipArgs = {
  duration: Scalars['Int'];
  userId: Scalars['ID'];
};


export type MutationAddTaskArgs = {
  description: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  role1?: InputMaybe<Scalars['String']>;
  role2?: InputMaybe<Scalars['String']>;
  sentence?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};


export type MutationAddTrainingArgs = {
  userId: Scalars['ID'];
};


export type MutationAddUserArgs = {
  comment?: InputMaybe<Scalars['String']>;
  communication: Array<InputMaybe<Scalars['String']>>;
  email: Scalars['String'];
  groupIds: Array<InputMaybe<Scalars['ID']>>;
  isActive: Scalars['Boolean'];
  lastname: Scalars['String'];
  middlename?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notifications: NotificationsInput;
  notificationsTypes: Array<InputMaybe<Scalars['String']>>;
  phone: Scalars['String'];
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subscribed: Scalars['Boolean'];
  types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationCancelMessengerVerificationArgs = {
  messengers: Array<InputMaybe<Scalars['String']>>;
};


export type MutationChangeBalanceArgs = {
  balance: Scalars['Float'];
  userId: Scalars['ID'];
};


export type MutationChangeFreeDuelsArgs = {
  code: Scalars['Int'];
  freeDuels: Scalars['Int'];
  userId: Scalars['ID'];
};


export type MutationChangeMembershipEndDateArgs = {
  code: Scalars['Int'];
  endDate: Scalars['String'];
  membershipId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationChangeTrainingStartDateArgs = {
  code: Scalars['Int'];
  startDate: Scalars['String'];
  trainingId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationDeleteAdminArgs = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationDeleteDuelArgs = {
  duelId: Scalars['ID'];
};


export type MutationDeleteGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteInstructionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTaskArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserFromGroupArgs = {
  groupId: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationImportUsersArgs = {
  file: Scalars['String'];
  groupId: Scalars['ID'];
};


export type MutationInviteInGroupArgs = {
  email: Scalars['String'];
  groupId: Scalars['ID'];
};


export type MutationOrganizeDuelArgs = {
  date: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  players: PlayersInput;
  streamKey?: InputMaybe<Scalars['String']>;
  taskId: Scalars['String'];
  type: Scalars['String'];
};


export type MutationSendComplaintArgs = {
  complaint: Scalars['String'];
  duelId: Scalars['String'];
  groupId: Scalars['String'];
  messages?: InputMaybe<Array<InputMaybe<MessageInput>>>;
};


export type MutationSendComplaintFromDuelArgs = {
  duelId: Scalars['ID'];
};


export type MutationSendQuestionArgs = {
  email: Scalars['String'];
  question: Scalars['String'];
};


export type MutationSetAdminArgs = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationUpdateDuelArgs = {
  date: Scalars['String'];
  duelId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  players: PlayersInput;
  streamKey?: InputMaybe<Scalars['String']>;
  taskId: Scalars['String'];
  type: Scalars['String'];
};


export type MutationUpdateDuelsInfoArgs = {
  id: Scalars['ID'];
  text1: Scalars['String'];
  text2: Scalars['String'];
  text3: Scalars['String'];
  text4: Scalars['String'];
};


export type MutationUpdateEnglishNameArgs = {
  englishLastname: Scalars['String'];
  englishName: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationUpdateGroupArgs = {
  dateFrom: Scalars['Int'];
  dateTo: Scalars['Int'];
  emailInfo?: InputMaybe<EmailInfoInput>;
  id: Scalars['ID'];
  instructions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  tasks?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tasksFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  time?: InputMaybe<TimeInfoInput>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  typesFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationUpdateInstructionArgs = {
  displayForAll?: InputMaybe<Scalars['Boolean']>;
  displayForPermission?: InputMaybe<Scalars['String']>;
  html: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateSettingsArgs = {
  diplomaEn: DiplomaSettingsInput;
  diplomaRu: DiplomaSettingsInput;
  duelPrice: DuelPriceInput;
  id: Scalars['ID'];
  training: TrainingSettingsInput;
};


export type MutationUpdateTaskArgs = {
  description: Scalars['String'];
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  role1?: InputMaybe<Scalars['String']>;
  role2?: InputMaybe<Scalars['String']>;
  sentence?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateUserArgs = {
  additionalInfo?: InputMaybe<AdditionalInfoInput>;
  comment?: InputMaybe<Scalars['String']>;
  communication: Array<InputMaybe<Scalars['String']>>;
  email: Scalars['String'];
  groupIds: Array<InputMaybe<Scalars['ID']>>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  lastname: Scalars['String'];
  middlename?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notifications: NotificationsInput;
  notificationsTypes: Array<InputMaybe<Scalars['String']>>;
  phone: Scalars['String'];
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subscribed: Scalars['Boolean'];
  types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationVoteTaskArgs = {
  id: Scalars['ID'];
  score: Scalars['Int'];
};

export type NotificationDetails = {
  __typename?: 'NotificationDetails';
  selected: Scalars['Boolean'];
  verified: Scalars['Boolean'];
};

export type NotificationDetailsInput = {
  selected: Scalars['Boolean'];
  verified: Scalars['Boolean'];
};

export type Notifications = {
  __typename?: 'Notifications';
  email?: Maybe<NotificationDetails>;
  telegram?: Maybe<NotificationDetails>;
  viber?: Maybe<NotificationDetails>;
  whatsapp?: Maybe<NotificationDetails>;
};

export type NotificationsInput = {
  email?: InputMaybe<NotificationDetailsInput>;
  telegram?: InputMaybe<NotificationDetailsInput>;
  viber?: InputMaybe<NotificationDetailsInput>;
  whatsapp?: InputMaybe<NotificationDetailsInput>;
};

export type OkMessage = {
  __typename?: 'OkMessage';
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
};

export type ParticipateWith = {
  __typename?: 'ParticipateWith';
  group?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Period = {
  __typename?: 'Period';
  timeFrom?: Maybe<Scalars['String']>;
  timeTo?: Maybe<Scalars['String']>;
  weekDay?: Maybe<Scalars['Int']>;
};

export type PeriodInput = {
  timeFrom?: InputMaybe<Scalars['String']>;
  timeTo?: InputMaybe<Scalars['String']>;
  weekDay?: InputMaybe<Scalars['Int']>;
};

export type Permissions = {
  __typename?: 'Permissions';
  accessAllGroups?: Maybe<Scalars['Boolean']>;
  accessUserPage?: Maybe<Scalars['Boolean']>;
  adminChat?: Maybe<Scalars['Boolean']>;
  allTime?: Maybe<Scalars['Boolean']>;
  broadcastYoutube?: Maybe<Scalars['Boolean']>;
  connectDuel?: Maybe<Scalars['Boolean']>;
  createDuel?: Maybe<Scalars['Boolean']>;
  createGroup?: Maybe<Scalars['Boolean']>;
  createInstruction?: Maybe<Scalars['Boolean']>;
  createTask?: Maybe<Scalars['Boolean']>;
  duelTypes?: Maybe<Array<Scalars['String']>>;
  editBalance?: Maybe<Scalars['Boolean']>;
  editDateMembership?: Maybe<Scalars['Boolean']>;
  editDateTraining?: Maybe<Scalars['Boolean']>;
  editPayments?: Maybe<Scalars['Boolean']>;
  editUserData?: Maybe<Scalars['Boolean']>;
  giveFreeDuels?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<Array<Maybe<Scalars['String']>>>;
  organizeDuel?: Maybe<Scalars['Boolean']>;
  participateFree?: Maybe<Scalars['Boolean']>;
  participateFreeJudge?: Maybe<Scalars['Boolean']>;
  participateFreeMaster?: Maybe<Scalars['Boolean']>;
  participateFreePlayer?: Maybe<Scalars['Boolean']>;
  paymentMethods?: Maybe<Array<Scalars['String']>>;
  periods?: Maybe<Array<Maybe<Period>>>;
  registerUser?: Maybe<Scalars['Boolean']>;
  seeTransactions?: Maybe<Scalars['Boolean']>;
  seeUserData?: Maybe<Scalars['Boolean']>;
  seeVideo?: Maybe<Scalars['Boolean']>;
  seeVideoAllGroups?: Maybe<Scalars['Boolean']>;
  seeVideoCompleted?: Maybe<Scalars['Boolean']>;
  seeVideoNotCompleted?: Maybe<Scalars['Boolean']>;
  setAdmin?: Maybe<Scalars['Boolean']>;
  setDuelTime?: Maybe<Scalars['Boolean']>;
  setDuelType?: Maybe<Scalars['Boolean']>;
  setInstruction?: Maybe<Scalars['Boolean']>;
  setTasks?: Maybe<Scalars['Boolean']>;
  tasks?: Maybe<Array<Scalars['String']>>;
};

export type PlayersInput = {
  judge1?: InputMaybe<Scalars['Int']>;
  judge2?: InputMaybe<Scalars['Int']>;
  judge3?: InputMaybe<Scalars['Int']>;
  master?: InputMaybe<Scalars['Int']>;
  player1?: InputMaybe<Scalars['Int']>;
  player2?: InputMaybe<Scalars['Int']>;
};

export type Purchase = {
  __typename?: 'Purchase';
  freeDuels?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isShowEnglishName?: Maybe<Scalars['Boolean']>;
  memberships?: Maybe<Array<Maybe<Membership>>>;
  trainings?: Maybe<Array<Maybe<Training>>>;
  userId: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  allRoles?: Maybe<Array<Maybe<Details>>>;
  allTypes?: Maybe<Array<Maybe<TypeInfo>>>;
  allUsersEmailsToComplaint?: Maybe<Array<Maybe<GroupEmailComplaint>>>;
  authUser?: Maybe<UserCredentials>;
  diplomas?: Maybe<Array<Maybe<Diploma>>>;
  duel?: Maybe<Duel>;
  duelTypesPrice?: Maybe<DuelPrice>;
  duels?: Maybe<Duels>;
  duelsForReconnect?: Maybe<Array<Maybe<DuelLobby>>>;
  duelsInfo?: Maybe<DuelsInfo>;
  groups?: Maybe<Groups>;
  instruction?: Maybe<Instruction>;
  purchase?: Maybe<Purchase>;
  refreshAuthUser?: Maybe<UserCredentials>;
  settings?: Maybe<Settings>;
  task?: Maybe<Task>;
  tasks?: Maybe<Tasks>;
  tasksForSelect?: Maybe<Array<Maybe<Task>>>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  user?: Maybe<User>;
  userDuelToken?: Maybe<DuelCredentials>;
  userGroups?: Maybe<Array<Maybe<Group>>>;
  userInfo?: Maybe<User>;
  userStatistics?: Maybe<UserStatistics>;
  users?: Maybe<Users>;
};


export type QueryAllUsersEmailsToComplaintArgs = {
  userId: Scalars['String'];
};


export type QueryAuthUserArgs = {
  accessToken: Scalars['String'];
};


export type QueryDiplomasArgs = {
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  lastname?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryDuelArgs = {
  id: Scalars['ID'];
};


export type QueryDuelsArgs = {
  dates?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGroupsArgs = {
  admin?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryPurchaseArgs = {
  userId: Scalars['ID'];
};


export type QueryRefreshAuthUserArgs = {
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryTasksArgs = {
  author?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duelType?: InputMaybe<Scalars['String']>;
  firstPhrase?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  rateFrom?: InputMaybe<Scalars['Int']>;
  rateTo?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type QueryTransactionsArgs = {
  amountFrom?: InputMaybe<Scalars['Int']>;
  amountTo?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  dates?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserDuelTokenArgs = {
  duelId?: InputMaybe<Scalars['String']>;
  pass?: InputMaybe<Scalars['String']>;
};


export type QueryUserGroupsArgs = {
  userId: Scalars['ID'];
};


export type QueryUserStatisticsArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  code?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  filterByRole?: InputMaybe<Scalars['String']>;
  filterByType?: InputMaybe<Scalars['String']>;
  groupId: Scalars['ID'];
  lastname?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  middlename?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type Settings = {
  __typename?: 'Settings';
  diplomaEn: DiplomaSettings;
  diplomaRu: DiplomaSettings;
  duelPrice?: Maybe<DuelPrice>;
  id: Scalars['ID'];
  training: TrainingSettings;
};

export type Task = {
  __typename?: 'Task';
  author?: Maybe<TaskAuthor>;
  description?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  role1?: Maybe<Scalars['String']>;
  role2?: Maybe<Scalars['String']>;
  sentence?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TaskAuthor = {
  __typename?: 'TaskAuthor';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type Tasks = {
  __typename?: 'Tasks';
  tasks?: Maybe<Array<Maybe<Task>>>;
  total?: Maybe<Scalars['Int']>;
};

export type TimeInfo = {
  __typename?: 'TimeInfo';
  allTime?: Maybe<Scalars['Boolean']>;
  periods?: Maybe<Array<Maybe<Period>>>;
  timeFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TimeInfoInput = {
  allTime?: InputMaybe<Scalars['Boolean']>;
  periods?: InputMaybe<Array<InputMaybe<PeriodInput>>>;
  timeFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Training = {
  __typename?: 'Training';
  diplomaEnNumber?: Maybe<Scalars['String']>;
  diplomaEnUrl?: Maybe<Scalars['String']>;
  diplomaNumber?: Maybe<Scalars['String']>;
  diplomaUrl?: Maybe<Scalars['String']>;
  duration?: Maybe<TrainingDuration>;
  endDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isChecked?: Maybe<Scalars['Boolean']>;
  payDate?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
};

export type TrainingDuration = {
  __typename?: 'TrainingDuration';
  days?: Maybe<Scalars['Int']>;
  months?: Maybe<Scalars['Int']>;
};

export type TrainingSettings = {
  __typename?: 'TrainingSettings';
  days: Scalars['Int'];
  diplomaHonoursPoint: Scalars['Int'];
  diplomaPoint: Scalars['Int'];
  months: Scalars['Int'];
};

export type TrainingSettingsInput = {
  days: Scalars['Int'];
  diplomaHonoursPoint: Scalars['Int'];
  diplomaPoint: Scalars['Int'];
  months: Scalars['Int'];
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Int'];
  code?: Maybe<Scalars['Int']>;
  comment: Scalars['String'];
  date: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isCompleted?: Maybe<Scalars['Boolean']>;
  type: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type TypeInfo = {
  __typename?: 'TypeInfo';
  allTime?: Maybe<Scalars['Boolean']>;
  duelTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  periods?: Maybe<Array<Maybe<Period>>>;
  tasks?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type User = {
  __typename?: 'User';
  additionalInfo?: Maybe<AdditionalInfo>;
  balance: Scalars['Float'];
  code: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  communication?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['String'];
  duelAccess?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  englishLastname?: Maybe<Scalars['String']>;
  englishName?: Maybe<Scalars['String']>;
  groupIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id: Scalars['ID'];
  instructions?: Maybe<Array<Maybe<Details>>>;
  isActive: Scalars['Boolean'];
  isGroupAdmin?: Maybe<Scalars['Boolean']>;
  isGroupAuthor?: Maybe<Scalars['Boolean']>;
  isInvited?: Maybe<Scalars['Boolean']>;
  lastname: Scalars['String'];
  middlename?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notifications: Notifications;
  notificationsTypes?: Maybe<Array<Scalars['String']>>;
  permissions?: Maybe<Permissions>;
  phone: Scalars['String'];
  roles?: Maybe<Array<Details>>;
  subscribed: Scalars['Boolean'];
  types?: Maybe<Array<Details>>;
};

export type UserCredentials = {
  __typename?: 'UserCredentials';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type UserStatistics = {
  __typename?: 'UserStatistics';
  express?: Maybe<UserStatisticsRole>;
  mini?: Maybe<UserStatisticsRole>;
  mini2?: Maybe<UserStatisticsRole>;
  negotiation?: Maybe<UserStatisticsRole>;
  totalPoints?: Maybe<Scalars['Int']>;
};

export type UserStatisticsPlayerPoints = {
  __typename?: 'UserStatisticsPlayerPoints';
  defeat?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  win?: Maybe<Scalars['Int']>;
};

export type UserStatisticsPoints = {
  __typename?: 'UserStatisticsPoints';
  points?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type UserStatisticsRole = {
  __typename?: 'UserStatisticsRole';
  judge?: Maybe<UserStatisticsPoints>;
  master?: Maybe<UserStatisticsPoints>;
  player?: Maybe<UserStatisticsPlayerPoints>;
};

export type Users = {
  __typename?: 'Users';
  roleFilters?: Maybe<Array<Maybe<FilterItem>>>;
  total?: Maybe<Scalars['Int']>;
  typeFilters?: Maybe<Array<Maybe<FilterItem>>>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type VoteResult = {
  __typename?: 'VoteResult';
  rating?: Maybe<Scalars['Float']>;
};

export type FilterItem = {
  __typename?: 'filterItem';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type UpdateGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  dateFrom: Scalars['Int'];
  dateTo: Scalars['Int'];
  types?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  typesFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  tasks?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  tasksFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  time?: InputMaybe<TimeInfoInput>;
  emailInfo?: InputMaybe<EmailInfoInput>;
  instructions?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type UpdateGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateGroup?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type AddGroupMutationVariables = Exact<{
  name: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  dateFrom: Scalars['Int'];
  dateTo: Scalars['Int'];
  types?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  typesFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  tasks?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  tasksFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  time?: InputMaybe<TimeInfoInput>;
  instructions?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  emailInfo?: InputMaybe<EmailInfoInput>;
}>;


export type AddGroupMutation = (
  { __typename?: 'Mutation' }
  & { addGroup?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type SetAdminMutationVariables = Exact<{
  userId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type SetAdminMutation = (
  { __typename?: 'Mutation' }
  & { setAdmin?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type DeleteAdminMutationVariables = Exact<{
  userId: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type DeleteAdminMutation = (
  { __typename?: 'Mutation' }
  & { deleteAdmin?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type DeleteGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroup?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type ImportUsersMutationVariables = Exact<{
  file: Scalars['String'];
  groupId: Scalars['ID'];
}>;


export type ImportUsersMutation = (
  { __typename?: 'Mutation' }
  & { importUsers?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type AddTaskMutationVariables = Exact<{
  type: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  role1?: InputMaybe<Scalars['String']>;
  role2?: InputMaybe<Scalars['String']>;
  sentence?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
}>;


export type AddTaskMutation = (
  { __typename?: 'Mutation' }
  & { addTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'name' | 'description' | 'role1' | 'role2' | 'sentence' | 'rating' | 'isActive'>
    & { author?: Maybe<(
      { __typename?: 'TaskAuthor' }
      & Pick<TaskAuthor, 'id' | 'name'>
    )> }
  )> }
);

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { deleteTask?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  type: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  role1?: InputMaybe<Scalars['String']>;
  role2?: InputMaybe<Scalars['String']>;
  sentence?: InputMaybe<Scalars['String']>;
}>;


export type UpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'name' | 'description' | 'role1' | 'role2' | 'sentence' | 'rating' | 'isActive'>
    & { author?: Maybe<(
      { __typename?: 'TaskAuthor' }
      & Pick<TaskAuthor, 'id' | 'name'>
    )> }
  )> }
);

export type VoteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  score: Scalars['Int'];
}>;


export type VoteTaskMutation = (
  { __typename?: 'Mutation' }
  & { voteTask?: Maybe<(
    { __typename?: 'VoteResult' }
    & Pick<VoteResult, 'rating'>
  )> }
);

export type AddUserMutationVariables = Exact<{
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  email: Scalars['String'];
  lastname: Scalars['String'];
  middlename?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  groupIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  phone: Scalars['String'];
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  communication: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  notifications: NotificationsInput;
  notificationsTypes: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  subscribed: Scalars['Boolean'];
}>;


export type AddUserMutation = (
  { __typename?: 'Mutation' }
  & { addUser?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  lastname: Scalars['String'];
  middlename?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  phone: Scalars['String'];
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  comment?: InputMaybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  groupIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  communication: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  notifications: NotificationsInput;
  notificationsTypes: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  subscribed: Scalars['Boolean'];
  additionalInfo?: InputMaybe<AdditionalInfoInput>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type InviteInGroupMutationVariables = Exact<{
  email: Scalars['String'];
  groupId: Scalars['ID'];
}>;


export type InviteInGroupMutation = (
  { __typename?: 'Mutation' }
  & { inviteInGroup?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
  groupId: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserFromGroup?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id'>
  )> }
);

export type UpdateInstructionMutationVariables = Exact<{
  id: Scalars['ID'];
  html: Scalars['String'];
  name: Scalars['String'];
  displayForAll?: InputMaybe<Scalars['Boolean']>;
  displayForPermission?: InputMaybe<Scalars['String']>;
}>;


export type UpdateInstructionMutation = (
  { __typename?: 'Mutation' }
  & { updateInstruction?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type AddInstructionMutationVariables = Exact<{
  html: Scalars['String'];
  name: Scalars['String'];
  displayForAll?: InputMaybe<Scalars['Boolean']>;
  displayForPermission?: InputMaybe<Scalars['String']>;
}>;


export type AddInstructionMutation = (
  { __typename?: 'Mutation' }
  & { addInstruction?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type DeleteInstructionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteInstructionMutation = (
  { __typename?: 'Mutation' }
  & { deleteInstruction?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type SendComplaintFromDuelMutationVariables = Exact<{
  duelId: Scalars['ID'];
}>;


export type SendComplaintFromDuelMutation = (
  { __typename?: 'Mutation' }
  & { sendComplaintFromDuel?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type SendComplaintMutationVariables = Exact<{
  messages?: InputMaybe<Array<InputMaybe<MessageInput>> | InputMaybe<MessageInput>>;
  complaint: Scalars['String'];
  duelId: Scalars['String'];
  groupId: Scalars['String'];
}>;


export type SendComplaintMutation = (
  { __typename?: 'Mutation' }
  & { sendComplaint?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type SendQuestionMutationVariables = Exact<{
  question: Scalars['String'];
  email: Scalars['String'];
}>;


export type SendQuestionMutation = (
  { __typename?: 'Mutation' }
  & { sendQuestion?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type UpdateDuelsInfoMutationVariables = Exact<{
  id: Scalars['ID'];
  text1: Scalars['String'];
  text2: Scalars['String'];
  text3: Scalars['String'];
  text4: Scalars['String'];
}>;


export type UpdateDuelsInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateDuelsInfo?: Maybe<(
    { __typename?: 'DuelsInfo' }
    & Pick<DuelsInfo, 'id' | 'text1' | 'text2' | 'text3' | 'text4'>
  )> }
);

export type OrganizeDuelMutationVariables = Exact<{
  type: Scalars['String'];
  date: Scalars['String'];
  taskId: Scalars['String'];
  players: PlayersInput;
  name?: InputMaybe<Scalars['String']>;
  streamKey?: InputMaybe<Scalars['String']>;
}>;


export type OrganizeDuelMutation = (
  { __typename?: 'Mutation' }
  & { organizeDuel?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type UpdateDuelMutationVariables = Exact<{
  duelId: Scalars['ID'];
  type: Scalars['String'];
  date: Scalars['String'];
  taskId: Scalars['String'];
  players: PlayersInput;
  name?: InputMaybe<Scalars['String']>;
  streamKey?: InputMaybe<Scalars['String']>;
}>;


export type UpdateDuelMutation = (
  { __typename?: 'Mutation' }
  & { updateDuel?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type DeleteDuelMutationVariables = Exact<{
  duelId: Scalars['ID'];
}>;


export type DeleteDuelMutation = (
  { __typename?: 'Mutation' }
  & { deleteDuel?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type CancelMessengerVerificationMutationVariables = Exact<{
  messengers: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type CancelMessengerVerificationMutation = (
  { __typename?: 'Mutation' }
  & { cancelMessengerVerification?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type UpdateSettingsMutationVariables = Exact<{
  id: Scalars['ID'];
  duelPrice: DuelPriceInput;
  training: TrainingSettingsInput;
  diplomaRu: DiplomaSettingsInput;
  diplomaEn: DiplomaSettingsInput;
}>;


export type UpdateSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateSettings?: Maybe<(
    { __typename?: 'Settings' }
    & Pick<Settings, 'id'>
    & { duelPrice?: Maybe<(
      { __typename?: 'DuelPrice' }
      & Pick<DuelPrice, 'negotiation' | 'mini' | 'mini2' | 'express'>
    )>, training: (
      { __typename?: 'TrainingSettings' }
      & Pick<TrainingSettings, 'months' | 'days' | 'diplomaPoint' | 'diplomaHonoursPoint'>
    ), diplomaRu: (
      { __typename?: 'DiplomaSettings' }
      & Pick<DiplomaSettings, 'generate' | 'title' | 'text1' | 'text2' | 'text3' | 'text4'>
    ), diplomaEn: (
      { __typename?: 'DiplomaSettings' }
      & Pick<DiplomaSettings, 'generate' | 'title' | 'text1' | 'text2' | 'text3' | 'text4'>
    ) }
  )> }
);

export type UpdateEnglishNameMutationVariables = Exact<{
  id: Scalars['ID'];
  englishName: Scalars['String'];
  englishLastname: Scalars['String'];
}>;


export type UpdateEnglishNameMutation = (
  { __typename?: 'Mutation' }
  & { updateEnglishName?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type ChangeFreeDuelsMutationVariables = Exact<{
  userId: Scalars['ID'];
  code: Scalars['Int'];
  freeDuels: Scalars['Int'];
}>;


export type ChangeFreeDuelsMutation = (
  { __typename?: 'Mutation' }
  & { changeFreeDuels?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type AddMembershipMutationVariables = Exact<{
  userId: Scalars['ID'];
  duration: Scalars['Int'];
}>;


export type AddMembershipMutation = (
  { __typename?: 'Mutation' }
  & { addMembership?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type AddTrainingMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type AddTrainingMutation = (
  { __typename?: 'Mutation' }
  & { addTraining?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type ChangeMembershipEndDateMutationVariables = Exact<{
  userId: Scalars['ID'];
  code: Scalars['Int'];
  membershipId: Scalars['ID'];
  endDate: Scalars['String'];
}>;


export type ChangeMembershipEndDateMutation = (
  { __typename?: 'Mutation' }
  & { changeMembershipEndDate?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type ChangeTrainingStartDateMutationVariables = Exact<{
  userId: Scalars['ID'];
  code: Scalars['Int'];
  trainingId: Scalars['ID'];
  startDate: Scalars['String'];
}>;


export type ChangeTrainingStartDateMutation = (
  { __typename?: 'Mutation' }
  & { changeTrainingStartDate?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type ChangeBalanceMutationVariables = Exact<{
  userId: Scalars['ID'];
  balance: Scalars['Float'];
}>;


export type ChangeBalanceMutation = (
  { __typename?: 'Mutation' }
  & { changeBalance?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type AddDiplomaMutationVariables = Exact<{
  userId: Scalars['ID'];
  trainingId: Scalars['ID'];
}>;


export type AddDiplomaMutation = (
  { __typename?: 'Mutation' }
  & { addDiploma?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'message'>
  )> }
);

export type AuthUserQueryVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type AuthUserQuery = (
  { __typename?: 'Query' }
  & { authUser?: Maybe<(
    { __typename?: 'UserCredentials' }
    & Pick<UserCredentials, 'accessToken' | 'refreshToken'>
  )> }
);

export type UserDuelTokenQueryVariables = Exact<{
  duelId?: InputMaybe<Scalars['String']>;
  pass?: InputMaybe<Scalars['String']>;
}>;


export type UserDuelTokenQuery = (
  { __typename?: 'Query' }
  & { userDuelToken?: Maybe<(
    { __typename?: 'DuelCredentials' }
    & Pick<DuelCredentials, 'accessToken' | 'liveKitToken'>
  )> }
);

export type UserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type UserInfoQuery = (
  { __typename?: 'Query' }
  & { userInfo?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'lastname' | 'middlename' | 'email' | 'comment' | 'phone' | 'groupIds' | 'isActive' | 'code' | 'createdAt' | 'subscribed' | 'communication' | 'notificationsTypes' | 'balance' | 'englishName' | 'englishLastname' | 'duelAccess'>
    & { roles?: Maybe<Array<(
      { __typename?: 'Details' }
      & Pick<Details, 'id' | 'name'>
    )>>, types?: Maybe<Array<(
      { __typename?: 'Details' }
      & Pick<Details, 'id' | 'name'>
    )>>, notifications: (
      { __typename?: 'Notifications' }
      & { email?: Maybe<(
        { __typename?: 'NotificationDetails' }
        & Pick<NotificationDetails, 'selected' | 'verified'>
      )>, telegram?: Maybe<(
        { __typename?: 'NotificationDetails' }
        & Pick<NotificationDetails, 'selected' | 'verified'>
      )>, viber?: Maybe<(
        { __typename?: 'NotificationDetails' }
        & Pick<NotificationDetails, 'selected' | 'verified'>
      )>, whatsapp?: Maybe<(
        { __typename?: 'NotificationDetails' }
        & Pick<NotificationDetails, 'selected' | 'verified'>
      )> }
    ), permissions?: Maybe<(
      { __typename?: 'Permissions' }
      & Pick<Permissions, 'accessUserPage' | 'accessAllGroups' | 'createGroup' | 'registerUser' | 'seeUserData' | 'editUserData' | 'editPayments' | 'editBalance' | 'giveFreeDuels' | 'editDateMembership' | 'editDateTraining' | 'seeTransactions' | 'setDuelTime' | 'setDuelType' | 'setTasks' | 'setAdmin' | 'organizeDuel' | 'broadcastYoutube' | 'createTask' | 'participateFree' | 'participateFreeJudge' | 'participateFreeMaster' | 'participateFreePlayer' | 'seeVideo' | 'seeVideoAllGroups' | 'seeVideoCompleted' | 'seeVideoNotCompleted' | 'adminChat' | 'createInstruction' | 'setInstruction' | 'duelTypes' | 'tasks' | 'instructions' | 'connectDuel' | 'createDuel' | 'allTime' | 'paymentMethods'>
      & { periods?: Maybe<Array<Maybe<(
        { __typename?: 'Period' }
        & Pick<Period, 'weekDay' | 'timeFrom' | 'timeTo'>
      )>>> }
    )>, instructions?: Maybe<Array<Maybe<(
      { __typename?: 'Details' }
      & Pick<Details, 'id' | 'name'>
    )>>> }
  )> }
);

export type GroupsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  admin?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
}>;


export type GroupsQuery = (
  { __typename?: 'Query' }
  & { groups?: Maybe<(
    { __typename?: 'Groups' }
    & Pick<Groups, 'total'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name' | 'dateFrom' | 'dateTo' | 'isActive' | 'count' | 'tasks' | 'tasksFrom' | 'types' | 'typesFrom' | 'instructions'>
      & { time?: Maybe<(
        { __typename?: 'TimeInfo' }
        & Pick<TimeInfo, 'timeFrom' | 'allTime'>
        & { periods?: Maybe<Array<Maybe<(
          { __typename?: 'Period' }
          & Pick<Period, 'weekDay' | 'timeFrom' | 'timeTo'>
        )>>> }
      )>, emailInfo?: Maybe<(
        { __typename?: 'EmailInfo' }
        & Pick<EmailInfo, 'email' | 'name'>
      )>, admins?: Maybe<Array<Maybe<(
        { __typename?: 'AdminInfo' }
        & Pick<AdminInfo, 'id' | 'code'>
      )>>>, author?: Maybe<(
        { __typename?: 'AdminInfo' }
        & Pick<AdminInfo, 'id' | 'code'>
      )> }
    )>>> }
  )> }
);

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'lastname' | 'middlename' | 'email' | 'phone' | 'comment' | 'groupIds' | 'isActive' | 'code' | 'createdAt' | 'communication' | 'notificationsTypes' | 'subscribed' | 'balance' | 'englishName' | 'englishLastname' | 'isGroupAdmin' | 'isGroupAuthor'>
    & { roles?: Maybe<Array<(
      { __typename?: 'Details' }
      & Pick<Details, 'id' | 'name'>
    )>>, types?: Maybe<Array<(
      { __typename?: 'Details' }
      & Pick<Details, 'id' | 'name'>
    )>>, notifications: (
      { __typename?: 'Notifications' }
      & { email?: Maybe<(
        { __typename?: 'NotificationDetails' }
        & Pick<NotificationDetails, 'selected' | 'verified'>
      )>, telegram?: Maybe<(
        { __typename?: 'NotificationDetails' }
        & Pick<NotificationDetails, 'selected' | 'verified'>
      )>, viber?: Maybe<(
        { __typename?: 'NotificationDetails' }
        & Pick<NotificationDetails, 'selected' | 'verified'>
      )>, whatsapp?: Maybe<(
        { __typename?: 'NotificationDetails' }
        & Pick<NotificationDetails, 'selected' | 'verified'>
      )> }
    ), additionalInfo?: Maybe<(
      { __typename?: 'AdditionalInfo' }
      & Pick<AdditionalInfo, 'howKnew' | 'whyChoose' | 'city' | 'profession' | 'jobPosition' | 'industry' | 'media' | 'age'>
    )>, permissions?: Maybe<(
      { __typename?: 'Permissions' }
      & Pick<Permissions, 'adminChat'>
    )> }
  )> }
);

export type UsersQueryVariables = Exact<{
  groupId: Scalars['ID'];
  sort?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  middlename?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  filterByRole?: InputMaybe<Scalars['String']>;
  filterByType?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'Users' }
    & Pick<Users, 'total'>
    & { users?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'lastname' | 'middlename' | 'email' | 'phone' | 'comment' | 'groupIds' | 'isActive' | 'code' | 'createdAt' | 'communication' | 'notificationsTypes' | 'subscribed' | 'balance' | 'englishName' | 'englishLastname' | 'isInvited' | 'isGroupAdmin' | 'isGroupAuthor' | 'duelAccess'>
      & { roles?: Maybe<Array<(
        { __typename?: 'Details' }
        & Pick<Details, 'id' | 'name'>
      )>>, types?: Maybe<Array<(
        { __typename?: 'Details' }
        & Pick<Details, 'id' | 'name'>
      )>>, notifications: (
        { __typename?: 'Notifications' }
        & { email?: Maybe<(
          { __typename?: 'NotificationDetails' }
          & Pick<NotificationDetails, 'selected' | 'verified'>
        )>, telegram?: Maybe<(
          { __typename?: 'NotificationDetails' }
          & Pick<NotificationDetails, 'selected' | 'verified'>
        )>, viber?: Maybe<(
          { __typename?: 'NotificationDetails' }
          & Pick<NotificationDetails, 'selected' | 'verified'>
        )>, whatsapp?: Maybe<(
          { __typename?: 'NotificationDetails' }
          & Pick<NotificationDetails, 'selected' | 'verified'>
        )> }
      ), additionalInfo?: Maybe<(
        { __typename?: 'AdditionalInfo' }
        & Pick<AdditionalInfo, 'howKnew' | 'whyChoose' | 'city' | 'profession' | 'jobPosition' | 'industry' | 'media' | 'age'>
      )>, permissions?: Maybe<(
        { __typename?: 'Permissions' }
        & Pick<Permissions, 'accessUserPage' | 'adminChat'>
      )> }
    )>>>, typeFilters?: Maybe<Array<Maybe<(
      { __typename?: 'filterItem' }
      & Pick<FilterItem, 'name' | 'value'>
    )>>>, roleFilters?: Maybe<Array<Maybe<(
      { __typename?: 'filterItem' }
      & Pick<FilterItem, 'name' | 'value'>
    )>>> }
  )> }
);

export type TaskQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'name' | 'description' | 'role1' | 'role2' | 'rating'>
    & { author?: Maybe<(
      { __typename?: 'TaskAuthor' }
      & Pick<TaskAuthor, 'name' | 'id'>
    )> }
  )> }
);

export type TasksQueryVariables = Exact<{
  duelType?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  rateFrom?: InputMaybe<Scalars['Int']>;
  rateTo?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  firstPhrase?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
}>;


export type TasksQuery = (
  { __typename?: 'Query' }
  & { tasks?: Maybe<(
    { __typename?: 'Tasks' }
    & Pick<Tasks, 'total'>
    & { tasks?: Maybe<Array<Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'isActive' | 'type' | 'name' | 'description' | 'role1' | 'role2' | 'sentence' | 'rating' | 'groups'>
      & { author?: Maybe<(
        { __typename?: 'TaskAuthor' }
        & Pick<TaskAuthor, 'id' | 'name'>
      )> }
    )>>> }
  )> }
);

export type TasksForSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type TasksForSelectQuery = (
  { __typename?: 'Query' }
  & { tasksForSelect?: Maybe<Array<Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'name' | 'description' | 'role1' | 'role2'>
  )>>> }
);

export type DuelTaskQueryVariables = Exact<{
  duelId: Scalars['ID'];
}>;


export type DuelTaskQuery = (
  { __typename?: 'Query' }
  & { duel?: Maybe<(
    { __typename?: 'Duel' }
    & Pick<Duel, 'type' | 'price'>
    & { task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'name' | 'description' | 'role1' | 'role2' | 'sentence'>
    )> }
  )> }
);

export type DuelsQueryVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['String']>;
  dates?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type DuelsQuery = (
  { __typename?: 'Query' }
  & { duels?: Maybe<(
    { __typename?: 'Duels' }
    & Pick<Duels, 'total'>
    & { duels?: Maybe<Array<Maybe<(
      { __typename?: 'Duel' }
      & Pick<Duel, 'id' | 'type' | 'price' | 'access' | 'date' | 'time' | 'status' | 'videoUrl' | 'userRole' | 'userVotes' | 'result' | 'streamKey' | 'organizerId' | 'name'>
      & { users?: Maybe<Array<Maybe<(
        { __typename?: 'DuelUser' }
        & Pick<DuelUser, 'userId' | 'code' | 'role'>
      )>>>, task?: Maybe<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'name' | 'description' | 'sentence' | 'role1' | 'role2'>
      )>, results?: Maybe<(
        { __typename?: 'DuelResult' }
        & { player1?: Maybe<(
          { __typename?: 'DuelVotes' }
          & Pick<DuelVotes, 'judge2' | 'judge3' | 'judge1'>
        )>, player2?: Maybe<(
          { __typename?: 'DuelVotes' }
          & Pick<DuelVotes, 'judge1' | 'judge2' | 'judge3'>
        )> }
      )>, agreements?: Maybe<(
        { __typename?: 'AgreementsResult' }
        & Pick<AgreementsResult, 'player1' | 'player2'>
        & { votes?: Maybe<(
          { __typename?: 'DuelVotes' }
          & Pick<DuelVotes, 'judge1' | 'judge2' | 'judge3'>
        )> }
      )>, characters?: Maybe<(
        { __typename?: 'DuelCharacters' }
        & Pick<DuelCharacters, 'player1' | 'player2'>
      )>, group?: Maybe<(
        { __typename?: 'Group' }
        & Pick<Group, 'id' | 'name'>
      )> }
    )>>> }
  )> }
);

export type DuelsForReconnectQueryVariables = Exact<{ [key: string]: never; }>;


export type DuelsForReconnectQuery = (
  { __typename?: 'Query' }
  & { duelsForReconnect?: Maybe<Array<Maybe<(
    { __typename?: 'DuelLobby' }
    & Pick<DuelLobby, 'id' | 'type' | 'taskId' | 'taskName' | 'date' | 'name' | 'organizerId' | 'price' | 'access' | 'role'>
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )> }
  )>>> }
);

export type InstructionQueryVariables = Exact<{ [key: string]: never; }>;


export type InstructionQuery = (
  { __typename?: 'Query' }
  & { instruction?: Maybe<(
    { __typename?: 'Instruction' }
    & Pick<Instruction, 'availableHtml'>
    & { menu?: Maybe<Array<Maybe<(
      { __typename?: 'MenuItem' }
      & Pick<MenuItem, 'id' | 'name'>
    )>>>, instructionsForEdit?: Maybe<Array<Maybe<(
      { __typename?: 'InstructionInfo' }
      & Pick<InstructionInfo, 'id' | 'html' | 'name'>
      & { author: (
        { __typename?: 'AuthorInfo' }
        & Pick<AuthorInfo, 'id' | 'code'>
      ), displayFor?: Maybe<(
        { __typename?: 'DisplayInfo' }
        & Pick<DisplayInfo, 'forAll' | 'permission' | 'groups'>
      )> }
    )>>> }
  )> }
);

export type UserStatisticsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserStatisticsQuery = (
  { __typename?: 'Query' }
  & { userStatistics?: Maybe<(
    { __typename?: 'UserStatistics' }
    & Pick<UserStatistics, 'totalPoints'>
    & { negotiation?: Maybe<(
      { __typename?: 'UserStatisticsRole' }
      & { master?: Maybe<(
        { __typename?: 'UserStatisticsPoints' }
        & Pick<UserStatisticsPoints, 'points' | 'total'>
      )>, player?: Maybe<(
        { __typename?: 'UserStatisticsPlayerPoints' }
        & Pick<UserStatisticsPlayerPoints, 'total' | 'points' | 'defeat' | 'win'>
      )>, judge?: Maybe<(
        { __typename?: 'UserStatisticsPoints' }
        & Pick<UserStatisticsPoints, 'total' | 'points'>
      )> }
    )>, express?: Maybe<(
      { __typename?: 'UserStatisticsRole' }
      & { master?: Maybe<(
        { __typename?: 'UserStatisticsPoints' }
        & Pick<UserStatisticsPoints, 'points' | 'total'>
      )>, player?: Maybe<(
        { __typename?: 'UserStatisticsPlayerPoints' }
        & Pick<UserStatisticsPlayerPoints, 'total' | 'points' | 'defeat' | 'win'>
      )>, judge?: Maybe<(
        { __typename?: 'UserStatisticsPoints' }
        & Pick<UserStatisticsPoints, 'total' | 'points'>
      )> }
    )>, mini?: Maybe<(
      { __typename?: 'UserStatisticsRole' }
      & { master?: Maybe<(
        { __typename?: 'UserStatisticsPoints' }
        & Pick<UserStatisticsPoints, 'points' | 'total'>
      )>, player?: Maybe<(
        { __typename?: 'UserStatisticsPlayerPoints' }
        & Pick<UserStatisticsPlayerPoints, 'total' | 'points' | 'defeat' | 'win'>
      )>, judge?: Maybe<(
        { __typename?: 'UserStatisticsPoints' }
        & Pick<UserStatisticsPoints, 'total' | 'points'>
      )> }
    )>, mini2?: Maybe<(
      { __typename?: 'UserStatisticsRole' }
      & { master?: Maybe<(
        { __typename?: 'UserStatisticsPoints' }
        & Pick<UserStatisticsPoints, 'points' | 'total'>
      )>, player?: Maybe<(
        { __typename?: 'UserStatisticsPlayerPoints' }
        & Pick<UserStatisticsPlayerPoints, 'total' | 'points' | 'defeat' | 'win'>
      )>, judge?: Maybe<(
        { __typename?: 'UserStatisticsPoints' }
        & Pick<UserStatisticsPoints, 'total' | 'points'>
      )> }
    )> }
  )> }
);

export type UserGroupsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserGroupsQuery = (
  { __typename?: 'Query' }
  & { userGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'dateFrom' | 'dateTo' | 'isActive'>
  )>>> }
);

export type DuelsInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type DuelsInfoQuery = (
  { __typename?: 'Query' }
  & { duelsInfo?: Maybe<(
    { __typename?: 'DuelsInfo' }
    & Pick<DuelsInfo, 'id' | 'text1' | 'text2' | 'text3' | 'text4'>
  )> }
);

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = (
  { __typename?: 'Query' }
  & { settings?: Maybe<(
    { __typename?: 'Settings' }
    & Pick<Settings, 'id'>
    & { duelPrice?: Maybe<(
      { __typename?: 'DuelPrice' }
      & Pick<DuelPrice, 'negotiation' | 'mini' | 'mini2' | 'express'>
    )>, training: (
      { __typename?: 'TrainingSettings' }
      & Pick<TrainingSettings, 'months' | 'days' | 'diplomaPoint' | 'diplomaHonoursPoint'>
    ), diplomaRu: (
      { __typename?: 'DiplomaSettings' }
      & Pick<DiplomaSettings, 'generate' | 'title' | 'text1' | 'text2' | 'text3' | 'text4'>
    ), diplomaEn: (
      { __typename?: 'DiplomaSettings' }
      & Pick<DiplomaSettings, 'generate' | 'title' | 'text1' | 'text2' | 'text3' | 'text4'>
    ) }
  )> }
);

export type DuelTypesPriceQueryVariables = Exact<{ [key: string]: never; }>;


export type DuelTypesPriceQuery = (
  { __typename?: 'Query' }
  & { duelTypesPrice?: Maybe<(
    { __typename?: 'DuelPrice' }
    & Pick<DuelPrice, 'negotiation' | 'mini' | 'mini2' | 'express'>
  )> }
);

export type PurchaseQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type PurchaseQuery = (
  { __typename?: 'Query' }
  & { purchase?: Maybe<(
    { __typename?: 'Purchase' }
    & Pick<Purchase, 'id' | 'userId' | 'isShowEnglishName' | 'freeDuels'>
    & { memberships?: Maybe<Array<Maybe<(
      { __typename?: 'Membership' }
      & Pick<Membership, 'id' | 'payDate' | 'startDate' | 'endDate' | 'duration' | 'price'>
    )>>>, trainings?: Maybe<Array<Maybe<(
      { __typename?: 'Training' }
      & Pick<Training, 'id' | 'payDate' | 'startDate' | 'endDate' | 'diplomaNumber' | 'diplomaUrl' | 'diplomaEnNumber' | 'diplomaEnUrl' | 'points' | 'isChecked' | 'price'>
      & { duration?: Maybe<(
        { __typename?: 'TrainingDuration' }
        & Pick<TrainingDuration, 'months' | 'days'>
      )> }
    )>>> }
  )> }
);

export type DiplomasQueryVariables = Exact<{
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
}>;


export type DiplomasQuery = (
  { __typename?: 'Query' }
  & { diplomas?: Maybe<Array<Maybe<(
    { __typename?: 'Diploma' }
    & Pick<Diploma, 'number' | 'url' | 'userId'>
  )>>> }
);

export type TransactionsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>;
  sort?: InputMaybe<Scalars['String']>;
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
  amountFrom?: InputMaybe<Scalars['Int']>;
  amountTo?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
}>;


export type TransactionsQuery = (
  { __typename?: 'Query' }
  & { transactions?: Maybe<Array<Maybe<(
    { __typename?: 'Transaction' }
    & Pick<Transaction, 'id' | 'date' | 'amount' | 'type' | 'comment' | 'userId' | 'code' | 'email' | 'isCompleted'>
  )>>> }
);

export type AllTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTypesQuery = (
  { __typename?: 'Query' }
  & { allTypes?: Maybe<Array<Maybe<(
    { __typename?: 'TypeInfo' }
    & Pick<TypeInfo, 'id' | 'name' | 'duelTypes' | 'tasks' | 'allTime'>
    & { periods?: Maybe<Array<Maybe<(
      { __typename?: 'Period' }
      & Pick<Period, 'weekDay' | 'timeFrom' | 'timeTo'>
    )>>> }
  )>>> }
);

export type AllRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllRolesQuery = (
  { __typename?: 'Query' }
  & { allRoles?: Maybe<Array<Maybe<(
    { __typename?: 'Details' }
    & Pick<Details, 'id' | 'name'>
  )>>> }
);

export type AllUsersEmailsToComplaintQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type AllUsersEmailsToComplaintQuery = (
  { __typename?: 'Query' }
  & { allUsersEmailsToComplaint?: Maybe<Array<Maybe<(
    { __typename?: 'GroupEmailComplaint' }
    & Pick<GroupEmailComplaint, 'name' | 'email'>
  )>>> }
);


export const UpdateGroupDocument = gql`
    mutation updateGroup($id: ID!, $name: String!, $isActive: Boolean!, $dateFrom: Int!, $dateTo: Int!, $types: [String], $typesFrom: [String], $tasks: [ID], $tasksFrom: [String], $time: TimeInfoInput, $emailInfo: EmailInfoInput, $instructions: [String]) {
  updateGroup(
    id: $id
    name: $name
    isActive: $isActive
    dateFrom: $dateFrom
    dateTo: $dateTo
    tasks: $tasks
    tasksFrom: $tasksFrom
    types: $types
    typesFrom: $typesFrom
    time: $time
    emailInfo: $emailInfo
    instructions: $instructions
  ) {
    id
    message
  }
}
    `;
export type UpdateGroupMutationFn = Apollo.MutationFunction<UpdateGroupMutation, UpdateGroupMutationVariables>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      isActive: // value for 'isActive'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      types: // value for 'types'
 *      typesFrom: // value for 'typesFrom'
 *      tasks: // value for 'tasks'
 *      tasksFrom: // value for 'tasksFrom'
 *      time: // value for 'time'
 *      emailInfo: // value for 'emailInfo'
 *      instructions: // value for 'instructions'
 *   },
 * });
 */
export function useUpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, options);
      }
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const AddGroupDocument = gql`
    mutation addGroup($name: String!, $isActive: Boolean, $dateFrom: Int!, $dateTo: Int!, $types: [String], $typesFrom: [String], $tasks: [ID], $tasksFrom: [String], $time: TimeInfoInput, $instructions: [String], $emailInfo: EmailInfoInput) {
  addGroup(
    name: $name
    isActive: $isActive
    dateFrom: $dateFrom
    dateTo: $dateTo
    tasks: $tasks
    tasksFrom: $tasksFrom
    types: $types
    typesFrom: $typesFrom
    time: $time
    instructions: $instructions
    emailInfo: $emailInfo
  ) {
    id
    message
  }
}
    `;
export type AddGroupMutationFn = Apollo.MutationFunction<AddGroupMutation, AddGroupMutationVariables>;

/**
 * __useAddGroupMutation__
 *
 * To run a mutation, you first call `useAddGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupMutation, { data, loading, error }] = useAddGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      isActive: // value for 'isActive'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      types: // value for 'types'
 *      typesFrom: // value for 'typesFrom'
 *      tasks: // value for 'tasks'
 *      tasksFrom: // value for 'tasksFrom'
 *      time: // value for 'time'
 *      instructions: // value for 'instructions'
 *      emailInfo: // value for 'emailInfo'
 *   },
 * });
 */
export function useAddGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddGroupMutation, AddGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGroupMutation, AddGroupMutationVariables>(AddGroupDocument, options);
      }
export type AddGroupMutationHookResult = ReturnType<typeof useAddGroupMutation>;
export type AddGroupMutationResult = Apollo.MutationResult<AddGroupMutation>;
export type AddGroupMutationOptions = Apollo.BaseMutationOptions<AddGroupMutation, AddGroupMutationVariables>;
export const SetAdminDocument = gql`
    mutation setAdmin($userId: ID!, $groupId: ID!) {
  setAdmin(userId: $userId, groupId: $groupId) {
    id
    message
  }
}
    `;
export type SetAdminMutationFn = Apollo.MutationFunction<SetAdminMutation, SetAdminMutationVariables>;

/**
 * __useSetAdminMutation__
 *
 * To run a mutation, you first call `useSetAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAdminMutation, { data, loading, error }] = useSetAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useSetAdminMutation(baseOptions?: Apollo.MutationHookOptions<SetAdminMutation, SetAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAdminMutation, SetAdminMutationVariables>(SetAdminDocument, options);
      }
export type SetAdminMutationHookResult = ReturnType<typeof useSetAdminMutation>;
export type SetAdminMutationResult = Apollo.MutationResult<SetAdminMutation>;
export type SetAdminMutationOptions = Apollo.BaseMutationOptions<SetAdminMutation, SetAdminMutationVariables>;
export const DeleteAdminDocument = gql`
    mutation deleteAdmin($userId: ID!, $groupId: ID!) {
  deleteAdmin(userId: $userId, groupId: $groupId) {
    id
    message
  }
}
    `;
export type DeleteAdminMutationFn = Apollo.MutationFunction<DeleteAdminMutation, DeleteAdminMutationVariables>;

/**
 * __useDeleteAdminMutation__
 *
 * To run a mutation, you first call `useDeleteAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminMutation, { data, loading, error }] = useDeleteAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteAdminMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdminMutation, DeleteAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdminMutation, DeleteAdminMutationVariables>(DeleteAdminDocument, options);
      }
export type DeleteAdminMutationHookResult = ReturnType<typeof useDeleteAdminMutation>;
export type DeleteAdminMutationResult = Apollo.MutationResult<DeleteAdminMutation>;
export type DeleteAdminMutationOptions = Apollo.BaseMutationOptions<DeleteAdminMutation, DeleteAdminMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation deleteGroup($id: ID!) {
  deleteGroup(id: $id) {
    id
    message
  }
}
    `;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const ImportUsersDocument = gql`
    mutation importUsers($file: String!, $groupId: ID!) {
  importUsers(file: $file, groupId: $groupId) {
    message
  }
}
    `;
export type ImportUsersMutationFn = Apollo.MutationFunction<ImportUsersMutation, ImportUsersMutationVariables>;

/**
 * __useImportUsersMutation__
 *
 * To run a mutation, you first call `useImportUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUsersMutation, { data, loading, error }] = useImportUsersMutation({
 *   variables: {
 *      file: // value for 'file'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useImportUsersMutation(baseOptions?: Apollo.MutationHookOptions<ImportUsersMutation, ImportUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportUsersMutation, ImportUsersMutationVariables>(ImportUsersDocument, options);
      }
export type ImportUsersMutationHookResult = ReturnType<typeof useImportUsersMutation>;
export type ImportUsersMutationResult = Apollo.MutationResult<ImportUsersMutation>;
export type ImportUsersMutationOptions = Apollo.BaseMutationOptions<ImportUsersMutation, ImportUsersMutationVariables>;
export const AddTaskDocument = gql`
    mutation addTask($type: String!, $name: String!, $description: String!, $role1: String, $role2: String, $sentence: String, $isActive: Boolean) {
  addTask(
    type: $type
    name: $name
    description: $description
    role1: $role1
    role2: $role2
    sentence: $sentence
    isActive: $isActive
  ) {
    id
    type
    name
    description
    role1
    role2
    sentence
    author {
      id
      name
    }
    rating
    isActive
  }
}
    `;
export type AddTaskMutationFn = Apollo.MutationFunction<AddTaskMutation, AddTaskMutationVariables>;

/**
 * __useAddTaskMutation__
 *
 * To run a mutation, you first call `useAddTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskMutation, { data, loading, error }] = useAddTaskMutation({
 *   variables: {
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      role1: // value for 'role1'
 *      role2: // value for 'role2'
 *      sentence: // value for 'sentence'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useAddTaskMutation(baseOptions?: Apollo.MutationHookOptions<AddTaskMutation, AddTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTaskMutation, AddTaskMutationVariables>(AddTaskDocument, options);
      }
export type AddTaskMutationHookResult = ReturnType<typeof useAddTaskMutation>;
export type AddTaskMutationResult = Apollo.MutationResult<AddTaskMutation>;
export type AddTaskMutationOptions = Apollo.BaseMutationOptions<AddTaskMutation, AddTaskMutationVariables>;
export const DeleteTaskDocument = gql`
    mutation deleteTask($id: ID!) {
  deleteTask(id: $id) {
    id
    message
  }
}
    `;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation updateTask($id: ID!, $isActive: Boolean, $type: String!, $name: String!, $description: String!, $role1: String, $role2: String, $sentence: String) {
  updateTask(
    id: $id
    isActive: $isActive
    type: $type
    name: $name
    description: $description
    role1: $role1
    role2: $role2
    sentence: $sentence
  ) {
    id
    type
    name
    description
    role1
    role2
    sentence
    author {
      id
      name
    }
    rating
    isActive
  }
}
    `;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      role1: // value for 'role1'
 *      role2: // value for 'role2'
 *      sentence: // value for 'sentence'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const VoteTaskDocument = gql`
    mutation voteTask($id: ID!, $score: Int!) {
  voteTask(id: $id, score: $score) {
    rating
  }
}
    `;
export type VoteTaskMutationFn = Apollo.MutationFunction<VoteTaskMutation, VoteTaskMutationVariables>;

/**
 * __useVoteTaskMutation__
 *
 * To run a mutation, you first call `useVoteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteTaskMutation, { data, loading, error }] = useVoteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      score: // value for 'score'
 *   },
 * });
 */
export function useVoteTaskMutation(baseOptions?: Apollo.MutationHookOptions<VoteTaskMutation, VoteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoteTaskMutation, VoteTaskMutationVariables>(VoteTaskDocument, options);
      }
export type VoteTaskMutationHookResult = ReturnType<typeof useVoteTaskMutation>;
export type VoteTaskMutationResult = Apollo.MutationResult<VoteTaskMutation>;
export type VoteTaskMutationOptions = Apollo.BaseMutationOptions<VoteTaskMutation, VoteTaskMutationVariables>;
export const AddUserDocument = gql`
    mutation addUser($isActive: Boolean!, $name: String!, $email: String!, $lastname: String!, $middlename: String, $comment: String, $groupIds: [ID]!, $types: [String], $phone: String!, $roles: [String], $communication: [String]!, $notifications: NotificationsInput!, $notificationsTypes: [String]!, $subscribed: Boolean!) {
  addUser(
    isActive: $isActive
    name: $name
    lastname: $lastname
    roles: $roles
    phone: $phone
    middlename: $middlename
    email: $email
    comment: $comment
    groupIds: $groupIds
    types: $types
    communication: $communication
    notifications: $notifications
    notificationsTypes: $notificationsTypes
    subscribed: $subscribed
  ) {
    message
  }
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      isActive: // value for 'isActive'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      lastname: // value for 'lastname'
 *      middlename: // value for 'middlename'
 *      comment: // value for 'comment'
 *      groupIds: // value for 'groupIds'
 *      types: // value for 'types'
 *      phone: // value for 'phone'
 *      roles: // value for 'roles'
 *      communication: // value for 'communication'
 *      notifications: // value for 'notifications'
 *      notificationsTypes: // value for 'notificationsTypes'
 *      subscribed: // value for 'subscribed'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $name: String!, $lastname: String!, $middlename: String, $email: String!, $phone: String!, $roles: [String], $comment: String, $isActive: Boolean!, $groupIds: [ID]!, $types: [String], $communication: [String]!, $notifications: NotificationsInput!, $notificationsTypes: [String]!, $subscribed: Boolean!, $additionalInfo: AdditionalInfoInput) {
  updateUser(
    id: $id
    name: $name
    lastname: $lastname
    middlename: $middlename
    email: $email
    phone: $phone
    roles: $roles
    comment: $comment
    isActive: $isActive
    groupIds: $groupIds
    types: $types
    communication: $communication
    notifications: $notifications
    notificationsTypes: $notificationsTypes
    subscribed: $subscribed
    additionalInfo: $additionalInfo
  ) {
    message
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      lastname: // value for 'lastname'
 *      middlename: // value for 'middlename'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      roles: // value for 'roles'
 *      comment: // value for 'comment'
 *      isActive: // value for 'isActive'
 *      groupIds: // value for 'groupIds'
 *      types: // value for 'types'
 *      communication: // value for 'communication'
 *      notifications: // value for 'notifications'
 *      notificationsTypes: // value for 'notificationsTypes'
 *      subscribed: // value for 'subscribed'
 *      additionalInfo: // value for 'additionalInfo'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const InviteInGroupDocument = gql`
    mutation inviteInGroup($email: String!, $groupId: ID!) {
  inviteInGroup(email: $email, groupId: $groupId) {
    message
  }
}
    `;
export type InviteInGroupMutationFn = Apollo.MutationFunction<InviteInGroupMutation, InviteInGroupMutationVariables>;

/**
 * __useInviteInGroupMutation__
 *
 * To run a mutation, you first call `useInviteInGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteInGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteInGroupMutation, { data, loading, error }] = useInviteInGroupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useInviteInGroupMutation(baseOptions?: Apollo.MutationHookOptions<InviteInGroupMutation, InviteInGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteInGroupMutation, InviteInGroupMutationVariables>(InviteInGroupDocument, options);
      }
export type InviteInGroupMutationHookResult = ReturnType<typeof useInviteInGroupMutation>;
export type InviteInGroupMutationResult = Apollo.MutationResult<InviteInGroupMutation>;
export type InviteInGroupMutationOptions = Apollo.BaseMutationOptions<InviteInGroupMutation, InviteInGroupMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: ID!, $groupId: ID!) {
  deleteUserFromGroup(id: $id, groupId: $groupId) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateInstructionDocument = gql`
    mutation updateInstruction($id: ID!, $html: String!, $name: String!, $displayForAll: Boolean, $displayForPermission: String) {
  updateInstruction(
    id: $id
    html: $html
    name: $name
    displayForAll: $displayForAll
    displayForPermission: $displayForPermission
  ) {
    message
  }
}
    `;
export type UpdateInstructionMutationFn = Apollo.MutationFunction<UpdateInstructionMutation, UpdateInstructionMutationVariables>;

/**
 * __useUpdateInstructionMutation__
 *
 * To run a mutation, you first call `useUpdateInstructionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstructionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstructionMutation, { data, loading, error }] = useUpdateInstructionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      html: // value for 'html'
 *      name: // value for 'name'
 *      displayForAll: // value for 'displayForAll'
 *      displayForPermission: // value for 'displayForPermission'
 *   },
 * });
 */
export function useUpdateInstructionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstructionMutation, UpdateInstructionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstructionMutation, UpdateInstructionMutationVariables>(UpdateInstructionDocument, options);
      }
export type UpdateInstructionMutationHookResult = ReturnType<typeof useUpdateInstructionMutation>;
export type UpdateInstructionMutationResult = Apollo.MutationResult<UpdateInstructionMutation>;
export type UpdateInstructionMutationOptions = Apollo.BaseMutationOptions<UpdateInstructionMutation, UpdateInstructionMutationVariables>;
export const AddInstructionDocument = gql`
    mutation addInstruction($html: String!, $name: String!, $displayForAll: Boolean, $displayForPermission: String) {
  addInstruction(
    html: $html
    name: $name
    displayForAll: $displayForAll
    displayForPermission: $displayForPermission
  ) {
    message
  }
}
    `;
export type AddInstructionMutationFn = Apollo.MutationFunction<AddInstructionMutation, AddInstructionMutationVariables>;

/**
 * __useAddInstructionMutation__
 *
 * To run a mutation, you first call `useAddInstructionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInstructionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInstructionMutation, { data, loading, error }] = useAddInstructionMutation({
 *   variables: {
 *      html: // value for 'html'
 *      name: // value for 'name'
 *      displayForAll: // value for 'displayForAll'
 *      displayForPermission: // value for 'displayForPermission'
 *   },
 * });
 */
export function useAddInstructionMutation(baseOptions?: Apollo.MutationHookOptions<AddInstructionMutation, AddInstructionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInstructionMutation, AddInstructionMutationVariables>(AddInstructionDocument, options);
      }
export type AddInstructionMutationHookResult = ReturnType<typeof useAddInstructionMutation>;
export type AddInstructionMutationResult = Apollo.MutationResult<AddInstructionMutation>;
export type AddInstructionMutationOptions = Apollo.BaseMutationOptions<AddInstructionMutation, AddInstructionMutationVariables>;
export const DeleteInstructionDocument = gql`
    mutation deleteInstruction($id: ID!) {
  deleteInstruction(id: $id) {
    message
  }
}
    `;
export type DeleteInstructionMutationFn = Apollo.MutationFunction<DeleteInstructionMutation, DeleteInstructionMutationVariables>;

/**
 * __useDeleteInstructionMutation__
 *
 * To run a mutation, you first call `useDeleteInstructionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInstructionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInstructionMutation, { data, loading, error }] = useDeleteInstructionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInstructionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInstructionMutation, DeleteInstructionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInstructionMutation, DeleteInstructionMutationVariables>(DeleteInstructionDocument, options);
      }
export type DeleteInstructionMutationHookResult = ReturnType<typeof useDeleteInstructionMutation>;
export type DeleteInstructionMutationResult = Apollo.MutationResult<DeleteInstructionMutation>;
export type DeleteInstructionMutationOptions = Apollo.BaseMutationOptions<DeleteInstructionMutation, DeleteInstructionMutationVariables>;
export const SendComplaintFromDuelDocument = gql`
    mutation sendComplaintFromDuel($duelId: ID!) {
  sendComplaintFromDuel(duelId: $duelId) {
    id
    message
  }
}
    `;
export type SendComplaintFromDuelMutationFn = Apollo.MutationFunction<SendComplaintFromDuelMutation, SendComplaintFromDuelMutationVariables>;

/**
 * __useSendComplaintFromDuelMutation__
 *
 * To run a mutation, you first call `useSendComplaintFromDuelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendComplaintFromDuelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendComplaintFromDuelMutation, { data, loading, error }] = useSendComplaintFromDuelMutation({
 *   variables: {
 *      duelId: // value for 'duelId'
 *   },
 * });
 */
export function useSendComplaintFromDuelMutation(baseOptions?: Apollo.MutationHookOptions<SendComplaintFromDuelMutation, SendComplaintFromDuelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendComplaintFromDuelMutation, SendComplaintFromDuelMutationVariables>(SendComplaintFromDuelDocument, options);
      }
export type SendComplaintFromDuelMutationHookResult = ReturnType<typeof useSendComplaintFromDuelMutation>;
export type SendComplaintFromDuelMutationResult = Apollo.MutationResult<SendComplaintFromDuelMutation>;
export type SendComplaintFromDuelMutationOptions = Apollo.BaseMutationOptions<SendComplaintFromDuelMutation, SendComplaintFromDuelMutationVariables>;
export const SendComplaintDocument = gql`
    mutation sendComplaint($messages: [MessageInput], $complaint: String!, $duelId: String!, $groupId: String!) {
  sendComplaint(
    messages: $messages
    complaint: $complaint
    duelId: $duelId
    groupId: $groupId
  ) {
    message
  }
}
    `;
export type SendComplaintMutationFn = Apollo.MutationFunction<SendComplaintMutation, SendComplaintMutationVariables>;

/**
 * __useSendComplaintMutation__
 *
 * To run a mutation, you first call `useSendComplaintMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendComplaintMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendComplaintMutation, { data, loading, error }] = useSendComplaintMutation({
 *   variables: {
 *      messages: // value for 'messages'
 *      complaint: // value for 'complaint'
 *      duelId: // value for 'duelId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useSendComplaintMutation(baseOptions?: Apollo.MutationHookOptions<SendComplaintMutation, SendComplaintMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendComplaintMutation, SendComplaintMutationVariables>(SendComplaintDocument, options);
      }
export type SendComplaintMutationHookResult = ReturnType<typeof useSendComplaintMutation>;
export type SendComplaintMutationResult = Apollo.MutationResult<SendComplaintMutation>;
export type SendComplaintMutationOptions = Apollo.BaseMutationOptions<SendComplaintMutation, SendComplaintMutationVariables>;
export const SendQuestionDocument = gql`
    mutation sendQuestion($question: String!, $email: String!) {
  sendQuestion(question: $question, email: $email) {
    message
  }
}
    `;
export type SendQuestionMutationFn = Apollo.MutationFunction<SendQuestionMutation, SendQuestionMutationVariables>;

/**
 * __useSendQuestionMutation__
 *
 * To run a mutation, you first call `useSendQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendQuestionMutation, { data, loading, error }] = useSendQuestionMutation({
 *   variables: {
 *      question: // value for 'question'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendQuestionMutation(baseOptions?: Apollo.MutationHookOptions<SendQuestionMutation, SendQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendQuestionMutation, SendQuestionMutationVariables>(SendQuestionDocument, options);
      }
export type SendQuestionMutationHookResult = ReturnType<typeof useSendQuestionMutation>;
export type SendQuestionMutationResult = Apollo.MutationResult<SendQuestionMutation>;
export type SendQuestionMutationOptions = Apollo.BaseMutationOptions<SendQuestionMutation, SendQuestionMutationVariables>;
export const UpdateDuelsInfoDocument = gql`
    mutation updateDuelsInfo($id: ID!, $text1: String!, $text2: String!, $text3: String!, $text4: String!) {
  updateDuelsInfo(
    id: $id
    text1: $text1
    text2: $text2
    text3: $text3
    text4: $text4
  ) {
    id
    text1
    text2
    text3
    text4
  }
}
    `;
export type UpdateDuelsInfoMutationFn = Apollo.MutationFunction<UpdateDuelsInfoMutation, UpdateDuelsInfoMutationVariables>;

/**
 * __useUpdateDuelsInfoMutation__
 *
 * To run a mutation, you first call `useUpdateDuelsInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDuelsInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDuelsInfoMutation, { data, loading, error }] = useUpdateDuelsInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text1: // value for 'text1'
 *      text2: // value for 'text2'
 *      text3: // value for 'text3'
 *      text4: // value for 'text4'
 *   },
 * });
 */
export function useUpdateDuelsInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDuelsInfoMutation, UpdateDuelsInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDuelsInfoMutation, UpdateDuelsInfoMutationVariables>(UpdateDuelsInfoDocument, options);
      }
export type UpdateDuelsInfoMutationHookResult = ReturnType<typeof useUpdateDuelsInfoMutation>;
export type UpdateDuelsInfoMutationResult = Apollo.MutationResult<UpdateDuelsInfoMutation>;
export type UpdateDuelsInfoMutationOptions = Apollo.BaseMutationOptions<UpdateDuelsInfoMutation, UpdateDuelsInfoMutationVariables>;
export const OrganizeDuelDocument = gql`
    mutation organizeDuel($type: String!, $date: String!, $taskId: String!, $players: PlayersInput!, $name: String, $streamKey: String) {
  organizeDuel(
    type: $type
    date: $date
    taskId: $taskId
    players: $players
    name: $name
    streamKey: $streamKey
  ) {
    id
    message
  }
}
    `;
export type OrganizeDuelMutationFn = Apollo.MutationFunction<OrganizeDuelMutation, OrganizeDuelMutationVariables>;

/**
 * __useOrganizeDuelMutation__
 *
 * To run a mutation, you first call `useOrganizeDuelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizeDuelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizeDuelMutation, { data, loading, error }] = useOrganizeDuelMutation({
 *   variables: {
 *      type: // value for 'type'
 *      date: // value for 'date'
 *      taskId: // value for 'taskId'
 *      players: // value for 'players'
 *      name: // value for 'name'
 *      streamKey: // value for 'streamKey'
 *   },
 * });
 */
export function useOrganizeDuelMutation(baseOptions?: Apollo.MutationHookOptions<OrganizeDuelMutation, OrganizeDuelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganizeDuelMutation, OrganizeDuelMutationVariables>(OrganizeDuelDocument, options);
      }
export type OrganizeDuelMutationHookResult = ReturnType<typeof useOrganizeDuelMutation>;
export type OrganizeDuelMutationResult = Apollo.MutationResult<OrganizeDuelMutation>;
export type OrganizeDuelMutationOptions = Apollo.BaseMutationOptions<OrganizeDuelMutation, OrganizeDuelMutationVariables>;
export const UpdateDuelDocument = gql`
    mutation updateDuel($duelId: ID!, $type: String!, $date: String!, $taskId: String!, $players: PlayersInput!, $name: String, $streamKey: String) {
  updateDuel(
    duelId: $duelId
    type: $type
    date: $date
    taskId: $taskId
    players: $players
    name: $name
    streamKey: $streamKey
  ) {
    id
    message
  }
}
    `;
export type UpdateDuelMutationFn = Apollo.MutationFunction<UpdateDuelMutation, UpdateDuelMutationVariables>;

/**
 * __useUpdateDuelMutation__
 *
 * To run a mutation, you first call `useUpdateDuelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDuelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDuelMutation, { data, loading, error }] = useUpdateDuelMutation({
 *   variables: {
 *      duelId: // value for 'duelId'
 *      type: // value for 'type'
 *      date: // value for 'date'
 *      taskId: // value for 'taskId'
 *      players: // value for 'players'
 *      name: // value for 'name'
 *      streamKey: // value for 'streamKey'
 *   },
 * });
 */
export function useUpdateDuelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDuelMutation, UpdateDuelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDuelMutation, UpdateDuelMutationVariables>(UpdateDuelDocument, options);
      }
export type UpdateDuelMutationHookResult = ReturnType<typeof useUpdateDuelMutation>;
export type UpdateDuelMutationResult = Apollo.MutationResult<UpdateDuelMutation>;
export type UpdateDuelMutationOptions = Apollo.BaseMutationOptions<UpdateDuelMutation, UpdateDuelMutationVariables>;
export const DeleteDuelDocument = gql`
    mutation deleteDuel($duelId: ID!) {
  deleteDuel(duelId: $duelId) {
    id
    message
  }
}
    `;
export type DeleteDuelMutationFn = Apollo.MutationFunction<DeleteDuelMutation, DeleteDuelMutationVariables>;

/**
 * __useDeleteDuelMutation__
 *
 * To run a mutation, you first call `useDeleteDuelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDuelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDuelMutation, { data, loading, error }] = useDeleteDuelMutation({
 *   variables: {
 *      duelId: // value for 'duelId'
 *   },
 * });
 */
export function useDeleteDuelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDuelMutation, DeleteDuelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDuelMutation, DeleteDuelMutationVariables>(DeleteDuelDocument, options);
      }
export type DeleteDuelMutationHookResult = ReturnType<typeof useDeleteDuelMutation>;
export type DeleteDuelMutationResult = Apollo.MutationResult<DeleteDuelMutation>;
export type DeleteDuelMutationOptions = Apollo.BaseMutationOptions<DeleteDuelMutation, DeleteDuelMutationVariables>;
export const CancelMessengerVerificationDocument = gql`
    mutation cancelMessengerVerification($messengers: [String]!) {
  cancelMessengerVerification(messengers: $messengers) {
    id
    message
  }
}
    `;
export type CancelMessengerVerificationMutationFn = Apollo.MutationFunction<CancelMessengerVerificationMutation, CancelMessengerVerificationMutationVariables>;

/**
 * __useCancelMessengerVerificationMutation__
 *
 * To run a mutation, you first call `useCancelMessengerVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMessengerVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMessengerVerificationMutation, { data, loading, error }] = useCancelMessengerVerificationMutation({
 *   variables: {
 *      messengers: // value for 'messengers'
 *   },
 * });
 */
export function useCancelMessengerVerificationMutation(baseOptions?: Apollo.MutationHookOptions<CancelMessengerVerificationMutation, CancelMessengerVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelMessengerVerificationMutation, CancelMessengerVerificationMutationVariables>(CancelMessengerVerificationDocument, options);
      }
export type CancelMessengerVerificationMutationHookResult = ReturnType<typeof useCancelMessengerVerificationMutation>;
export type CancelMessengerVerificationMutationResult = Apollo.MutationResult<CancelMessengerVerificationMutation>;
export type CancelMessengerVerificationMutationOptions = Apollo.BaseMutationOptions<CancelMessengerVerificationMutation, CancelMessengerVerificationMutationVariables>;
export const UpdateSettingsDocument = gql`
    mutation updateSettings($id: ID!, $duelPrice: DuelPriceInput!, $training: TrainingSettingsInput!, $diplomaRu: DiplomaSettingsInput!, $diplomaEn: DiplomaSettingsInput!) {
  updateSettings(
    id: $id
    duelPrice: $duelPrice
    training: $training
    diplomaRu: $diplomaRu
    diplomaEn: $diplomaEn
  ) {
    id
    duelPrice {
      negotiation
      mini
      mini2
      express
    }
    training {
      months
      days
      diplomaPoint
      diplomaHonoursPoint
    }
    diplomaRu {
      generate
      title
      text1
      text2
      text3
      text4
    }
    diplomaEn {
      generate
      title
      text1
      text2
      text3
      text4
    }
  }
}
    `;
export type UpdateSettingsMutationFn = Apollo.MutationFunction<UpdateSettingsMutation, UpdateSettingsMutationVariables>;

/**
 * __useUpdateSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingsMutation, { data, loading, error }] = useUpdateSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      duelPrice: // value for 'duelPrice'
 *      training: // value for 'training'
 *      diplomaRu: // value for 'diplomaRu'
 *      diplomaEn: // value for 'diplomaEn'
 *   },
 * });
 */
export function useUpdateSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSettingsMutation, UpdateSettingsMutationVariables>(UpdateSettingsDocument, options);
      }
export type UpdateSettingsMutationHookResult = ReturnType<typeof useUpdateSettingsMutation>;
export type UpdateSettingsMutationResult = Apollo.MutationResult<UpdateSettingsMutation>;
export type UpdateSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>;
export const UpdateEnglishNameDocument = gql`
    mutation updateEnglishName($id: ID!, $englishName: String!, $englishLastname: String!) {
  updateEnglishName(
    id: $id
    englishName: $englishName
    englishLastname: $englishLastname
  ) {
    message
  }
}
    `;
export type UpdateEnglishNameMutationFn = Apollo.MutationFunction<UpdateEnglishNameMutation, UpdateEnglishNameMutationVariables>;

/**
 * __useUpdateEnglishNameMutation__
 *
 * To run a mutation, you first call `useUpdateEnglishNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnglishNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnglishNameMutation, { data, loading, error }] = useUpdateEnglishNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      englishName: // value for 'englishName'
 *      englishLastname: // value for 'englishLastname'
 *   },
 * });
 */
export function useUpdateEnglishNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnglishNameMutation, UpdateEnglishNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnglishNameMutation, UpdateEnglishNameMutationVariables>(UpdateEnglishNameDocument, options);
      }
export type UpdateEnglishNameMutationHookResult = ReturnType<typeof useUpdateEnglishNameMutation>;
export type UpdateEnglishNameMutationResult = Apollo.MutationResult<UpdateEnglishNameMutation>;
export type UpdateEnglishNameMutationOptions = Apollo.BaseMutationOptions<UpdateEnglishNameMutation, UpdateEnglishNameMutationVariables>;
export const ChangeFreeDuelsDocument = gql`
    mutation changeFreeDuels($userId: ID!, $code: Int!, $freeDuels: Int!) {
  changeFreeDuels(userId: $userId, code: $code, freeDuels: $freeDuels) {
    message
  }
}
    `;
export type ChangeFreeDuelsMutationFn = Apollo.MutationFunction<ChangeFreeDuelsMutation, ChangeFreeDuelsMutationVariables>;

/**
 * __useChangeFreeDuelsMutation__
 *
 * To run a mutation, you first call `useChangeFreeDuelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeFreeDuelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeFreeDuelsMutation, { data, loading, error }] = useChangeFreeDuelsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      code: // value for 'code'
 *      freeDuels: // value for 'freeDuels'
 *   },
 * });
 */
export function useChangeFreeDuelsMutation(baseOptions?: Apollo.MutationHookOptions<ChangeFreeDuelsMutation, ChangeFreeDuelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeFreeDuelsMutation, ChangeFreeDuelsMutationVariables>(ChangeFreeDuelsDocument, options);
      }
export type ChangeFreeDuelsMutationHookResult = ReturnType<typeof useChangeFreeDuelsMutation>;
export type ChangeFreeDuelsMutationResult = Apollo.MutationResult<ChangeFreeDuelsMutation>;
export type ChangeFreeDuelsMutationOptions = Apollo.BaseMutationOptions<ChangeFreeDuelsMutation, ChangeFreeDuelsMutationVariables>;
export const AddMembershipDocument = gql`
    mutation addMembership($userId: ID!, $duration: Int!) {
  addMembership(userId: $userId, duration: $duration) {
    message
  }
}
    `;
export type AddMembershipMutationFn = Apollo.MutationFunction<AddMembershipMutation, AddMembershipMutationVariables>;

/**
 * __useAddMembershipMutation__
 *
 * To run a mutation, you first call `useAddMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMembershipMutation, { data, loading, error }] = useAddMembershipMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      duration: // value for 'duration'
 *   },
 * });
 */
export function useAddMembershipMutation(baseOptions?: Apollo.MutationHookOptions<AddMembershipMutation, AddMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMembershipMutation, AddMembershipMutationVariables>(AddMembershipDocument, options);
      }
export type AddMembershipMutationHookResult = ReturnType<typeof useAddMembershipMutation>;
export type AddMembershipMutationResult = Apollo.MutationResult<AddMembershipMutation>;
export type AddMembershipMutationOptions = Apollo.BaseMutationOptions<AddMembershipMutation, AddMembershipMutationVariables>;
export const AddTrainingDocument = gql`
    mutation addTraining($userId: ID!) {
  addTraining(userId: $userId) {
    message
  }
}
    `;
export type AddTrainingMutationFn = Apollo.MutationFunction<AddTrainingMutation, AddTrainingMutationVariables>;

/**
 * __useAddTrainingMutation__
 *
 * To run a mutation, you first call `useAddTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrainingMutation, { data, loading, error }] = useAddTrainingMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddTrainingMutation(baseOptions?: Apollo.MutationHookOptions<AddTrainingMutation, AddTrainingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTrainingMutation, AddTrainingMutationVariables>(AddTrainingDocument, options);
      }
export type AddTrainingMutationHookResult = ReturnType<typeof useAddTrainingMutation>;
export type AddTrainingMutationResult = Apollo.MutationResult<AddTrainingMutation>;
export type AddTrainingMutationOptions = Apollo.BaseMutationOptions<AddTrainingMutation, AddTrainingMutationVariables>;
export const ChangeMembershipEndDateDocument = gql`
    mutation changeMembershipEndDate($userId: ID!, $code: Int!, $membershipId: ID!, $endDate: String!) {
  changeMembershipEndDate(
    userId: $userId
    code: $code
    membershipId: $membershipId
    endDate: $endDate
  ) {
    message
  }
}
    `;
export type ChangeMembershipEndDateMutationFn = Apollo.MutationFunction<ChangeMembershipEndDateMutation, ChangeMembershipEndDateMutationVariables>;

/**
 * __useChangeMembershipEndDateMutation__
 *
 * To run a mutation, you first call `useChangeMembershipEndDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeMembershipEndDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeMembershipEndDateMutation, { data, loading, error }] = useChangeMembershipEndDateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      code: // value for 'code'
 *      membershipId: // value for 'membershipId'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useChangeMembershipEndDateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeMembershipEndDateMutation, ChangeMembershipEndDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeMembershipEndDateMutation, ChangeMembershipEndDateMutationVariables>(ChangeMembershipEndDateDocument, options);
      }
export type ChangeMembershipEndDateMutationHookResult = ReturnType<typeof useChangeMembershipEndDateMutation>;
export type ChangeMembershipEndDateMutationResult = Apollo.MutationResult<ChangeMembershipEndDateMutation>;
export type ChangeMembershipEndDateMutationOptions = Apollo.BaseMutationOptions<ChangeMembershipEndDateMutation, ChangeMembershipEndDateMutationVariables>;
export const ChangeTrainingStartDateDocument = gql`
    mutation changeTrainingStartDate($userId: ID!, $code: Int!, $trainingId: ID!, $startDate: String!) {
  changeTrainingStartDate(
    userId: $userId
    code: $code
    trainingId: $trainingId
    startDate: $startDate
  ) {
    message
  }
}
    `;
export type ChangeTrainingStartDateMutationFn = Apollo.MutationFunction<ChangeTrainingStartDateMutation, ChangeTrainingStartDateMutationVariables>;

/**
 * __useChangeTrainingStartDateMutation__
 *
 * To run a mutation, you first call `useChangeTrainingStartDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTrainingStartDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTrainingStartDateMutation, { data, loading, error }] = useChangeTrainingStartDateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      code: // value for 'code'
 *      trainingId: // value for 'trainingId'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useChangeTrainingStartDateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTrainingStartDateMutation, ChangeTrainingStartDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTrainingStartDateMutation, ChangeTrainingStartDateMutationVariables>(ChangeTrainingStartDateDocument, options);
      }
export type ChangeTrainingStartDateMutationHookResult = ReturnType<typeof useChangeTrainingStartDateMutation>;
export type ChangeTrainingStartDateMutationResult = Apollo.MutationResult<ChangeTrainingStartDateMutation>;
export type ChangeTrainingStartDateMutationOptions = Apollo.BaseMutationOptions<ChangeTrainingStartDateMutation, ChangeTrainingStartDateMutationVariables>;
export const ChangeBalanceDocument = gql`
    mutation changeBalance($userId: ID!, $balance: Float!) {
  changeBalance(userId: $userId, balance: $balance) {
    message
  }
}
    `;
export type ChangeBalanceMutationFn = Apollo.MutationFunction<ChangeBalanceMutation, ChangeBalanceMutationVariables>;

/**
 * __useChangeBalanceMutation__
 *
 * To run a mutation, you first call `useChangeBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBalanceMutation, { data, loading, error }] = useChangeBalanceMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      balance: // value for 'balance'
 *   },
 * });
 */
export function useChangeBalanceMutation(baseOptions?: Apollo.MutationHookOptions<ChangeBalanceMutation, ChangeBalanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeBalanceMutation, ChangeBalanceMutationVariables>(ChangeBalanceDocument, options);
      }
export type ChangeBalanceMutationHookResult = ReturnType<typeof useChangeBalanceMutation>;
export type ChangeBalanceMutationResult = Apollo.MutationResult<ChangeBalanceMutation>;
export type ChangeBalanceMutationOptions = Apollo.BaseMutationOptions<ChangeBalanceMutation, ChangeBalanceMutationVariables>;
export const AddDiplomaDocument = gql`
    mutation addDiploma($userId: ID!, $trainingId: ID!) {
  addDiploma(userId: $userId, trainingId: $trainingId) {
    message
  }
}
    `;
export type AddDiplomaMutationFn = Apollo.MutationFunction<AddDiplomaMutation, AddDiplomaMutationVariables>;

/**
 * __useAddDiplomaMutation__
 *
 * To run a mutation, you first call `useAddDiplomaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDiplomaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDiplomaMutation, { data, loading, error }] = useAddDiplomaMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      trainingId: // value for 'trainingId'
 *   },
 * });
 */
export function useAddDiplomaMutation(baseOptions?: Apollo.MutationHookOptions<AddDiplomaMutation, AddDiplomaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDiplomaMutation, AddDiplomaMutationVariables>(AddDiplomaDocument, options);
      }
export type AddDiplomaMutationHookResult = ReturnType<typeof useAddDiplomaMutation>;
export type AddDiplomaMutationResult = Apollo.MutationResult<AddDiplomaMutation>;
export type AddDiplomaMutationOptions = Apollo.BaseMutationOptions<AddDiplomaMutation, AddDiplomaMutationVariables>;
export const AuthUserDocument = gql`
    query authUser($accessToken: String!) {
  authUser(accessToken: $accessToken) {
    accessToken
    refreshToken
  }
}
    `;

/**
 * __useAuthUserQuery__
 *
 * To run a query within a React component, call `useAuthUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthUserQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useAuthUserQuery(baseOptions: Apollo.QueryHookOptions<AuthUserQuery, AuthUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthUserQuery, AuthUserQueryVariables>(AuthUserDocument, options);
      }
export function useAuthUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthUserQuery, AuthUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthUserQuery, AuthUserQueryVariables>(AuthUserDocument, options);
        }
export type AuthUserQueryHookResult = ReturnType<typeof useAuthUserQuery>;
export type AuthUserLazyQueryHookResult = ReturnType<typeof useAuthUserLazyQuery>;
export type AuthUserQueryResult = Apollo.QueryResult<AuthUserQuery, AuthUserQueryVariables>;
export const UserDuelTokenDocument = gql`
    query userDuelToken($duelId: String, $pass: String) {
  userDuelToken(duelId: $duelId, pass: $pass) {
    accessToken
    liveKitToken
  }
}
    `;

/**
 * __useUserDuelTokenQuery__
 *
 * To run a query within a React component, call `useUserDuelTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDuelTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDuelTokenQuery({
 *   variables: {
 *      duelId: // value for 'duelId'
 *      pass: // value for 'pass'
 *   },
 * });
 */
export function useUserDuelTokenQuery(baseOptions?: Apollo.QueryHookOptions<UserDuelTokenQuery, UserDuelTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDuelTokenQuery, UserDuelTokenQueryVariables>(UserDuelTokenDocument, options);
      }
export function useUserDuelTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDuelTokenQuery, UserDuelTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDuelTokenQuery, UserDuelTokenQueryVariables>(UserDuelTokenDocument, options);
        }
export type UserDuelTokenQueryHookResult = ReturnType<typeof useUserDuelTokenQuery>;
export type UserDuelTokenLazyQueryHookResult = ReturnType<typeof useUserDuelTokenLazyQuery>;
export type UserDuelTokenQueryResult = Apollo.QueryResult<UserDuelTokenQuery, UserDuelTokenQueryVariables>;
export const UserInfoDocument = gql`
    query userInfo {
  userInfo {
    id
    name
    lastname
    middlename
    email
    comment
    phone
    groupIds
    roles {
      id
      name
    }
    isActive
    code
    types {
      id
      name
    }
    createdAt
    subscribed
    communication
    notifications {
      email {
        selected
        verified
      }
      telegram {
        selected
        verified
      }
      viber {
        selected
        verified
      }
      whatsapp {
        selected
        verified
      }
    }
    notificationsTypes
    balance
    englishName
    englishLastname
    duelAccess
    permissions {
      accessUserPage
      accessAllGroups
      createGroup
      registerUser
      seeUserData
      editUserData
      editPayments
      editBalance
      giveFreeDuels
      editDateMembership
      editDateTraining
      seeTransactions
      setDuelTime
      setDuelType
      setTasks
      setAdmin
      organizeDuel
      broadcastYoutube
      createTask
      participateFree
      participateFreeJudge
      participateFreeMaster
      participateFreePlayer
      seeVideo
      seeVideoAllGroups
      seeVideoCompleted
      seeVideoNotCompleted
      adminChat
      createInstruction
      setInstruction
      duelTypes
      tasks
      instructions
      connectDuel
      createDuel
      periods {
        weekDay
        timeFrom
        timeTo
      }
      allTime
      paymentMethods
    }
    instructions {
      id
      name
    }
  }
}
    `;

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
      }
export function useUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
        }
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>;
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>;
export type UserInfoQueryResult = Apollo.QueryResult<UserInfoQuery, UserInfoQueryVariables>;
export const GroupsDocument = gql`
    query groups($offset: Int, $limit: Int, $sort: String, $name: String, $admin: String, $author: String, $id: String) {
  groups(
    offset: $offset
    limit: $limit
    sort: $sort
    name: $name
    admin: $admin
    author: $author
    id: $id
  ) {
    groups {
      id
      name
      dateFrom
      dateTo
      isActive
      count
      tasks
      tasksFrom
      types
      typesFrom
      time {
        periods {
          weekDay
          timeFrom
          timeTo
        }
        timeFrom
        allTime
      }
      emailInfo {
        email
        name
      }
      admins {
        id
        code
      }
      author {
        id
        code
      }
      instructions
    }
    total
  }
}
    `;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      name: // value for 'name'
 *      admin: // value for 'admin'
 *      author: // value for 'author'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
      }
export function useGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const UserDocument = gql`
    query user($id: ID!) {
  user(id: $id) {
    id
    name
    lastname
    middlename
    email
    phone
    comment
    groupIds
    roles {
      id
      name
    }
    isActive
    code
    types {
      id
      name
    }
    createdAt
    communication
    notifications {
      email {
        selected
        verified
      }
      telegram {
        selected
        verified
      }
      viber {
        selected
        verified
      }
      whatsapp {
        selected
        verified
      }
    }
    notificationsTypes
    subscribed
    additionalInfo {
      howKnew
      whyChoose
      city
      profession
      jobPosition
      industry
      media
      age
    }
    balance
    englishName
    englishLastname
    isGroupAdmin
    isGroupAuthor
    permissions {
      adminChat
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query users($groupId: ID!, $sort: String, $name: String, $lastname: String, $middlename: String, $email: String, $code: String, $filterByRole: String, $filterByType: String, $offset: Int, $limit: Int) {
  users(
    groupId: $groupId
    sort: $sort
    name: $name
    lastname: $lastname
    middlename: $middlename
    email: $email
    code: $code
    filterByRole: $filterByRole
    filterByType: $filterByType
    offset: $offset
    limit: $limit
  ) {
    users {
      id
      name
      lastname
      middlename
      email
      phone
      comment
      groupIds
      roles {
        id
        name
      }
      isActive
      code
      types {
        id
        name
      }
      createdAt
      communication
      notifications {
        email {
          selected
          verified
        }
        telegram {
          selected
          verified
        }
        viber {
          selected
          verified
        }
        whatsapp {
          selected
          verified
        }
      }
      notificationsTypes
      subscribed
      additionalInfo {
        howKnew
        whyChoose
        city
        profession
        jobPosition
        industry
        media
        age
      }
      balance
      englishName
      englishLastname
      permissions {
        accessUserPage
        adminChat
      }
      isInvited
      isGroupAdmin
      isGroupAuthor
      duelAccess
    }
    total
    typeFilters {
      name
      value
    }
    roleFilters {
      name
      value
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      sort: // value for 'sort'
 *      name: // value for 'name'
 *      lastname: // value for 'lastname'
 *      middlename: // value for 'middlename'
 *      email: // value for 'email'
 *      code: // value for 'code'
 *      filterByRole: // value for 'filterByRole'
 *      filterByType: // value for 'filterByType'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const TaskDocument = gql`
    query task($id: ID!) {
  task(id: $id) {
    id
    type
    name
    description
    role1
    role2
    author {
      name
      id
    }
    rating
  }
}
    `;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskQuery(baseOptions: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
      }
export function useTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
        }
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export const TasksDocument = gql`
    query tasks($duelType: String, $sort: String, $offset: Int, $limit: Int, $isActive: Boolean, $rateFrom: Int, $rateTo: Int, $title: String, $description: String, $firstPhrase: String, $role: String, $author: String) {
  tasks(
    duelType: $duelType
    sort: $sort
    offset: $offset
    limit: $limit
    isActive: $isActive
    rateFrom: $rateFrom
    rateTo: $rateTo
    title: $title
    description: $description
    firstPhrase: $firstPhrase
    role: $role
    author: $author
  ) {
    tasks {
      id
      isActive
      type
      name
      description
      role1
      role2
      sentence
      rating
      author {
        id
        name
      }
      groups
    }
    total
  }
}
    `;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      duelType: // value for 'duelType'
 *      sort: // value for 'sort'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      isActive: // value for 'isActive'
 *      rateFrom: // value for 'rateFrom'
 *      rateTo: // value for 'rateTo'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      firstPhrase: // value for 'firstPhrase'
 *      role: // value for 'role'
 *      author: // value for 'author'
 *   },
 * });
 */
export function useTasksQuery(baseOptions?: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
      }
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
        }
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export const TasksForSelectDocument = gql`
    query tasksForSelect {
  tasksForSelect {
    id
    type
    name
    description
    role1
    role2
  }
}
    `;

/**
 * __useTasksForSelectQuery__
 *
 * To run a query within a React component, call `useTasksForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksForSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useTasksForSelectQuery(baseOptions?: Apollo.QueryHookOptions<TasksForSelectQuery, TasksForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksForSelectQuery, TasksForSelectQueryVariables>(TasksForSelectDocument, options);
      }
export function useTasksForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksForSelectQuery, TasksForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksForSelectQuery, TasksForSelectQueryVariables>(TasksForSelectDocument, options);
        }
export type TasksForSelectQueryHookResult = ReturnType<typeof useTasksForSelectQuery>;
export type TasksForSelectLazyQueryHookResult = ReturnType<typeof useTasksForSelectLazyQuery>;
export type TasksForSelectQueryResult = Apollo.QueryResult<TasksForSelectQuery, TasksForSelectQueryVariables>;
export const DuelTaskDocument = gql`
    query duelTask($duelId: ID!) {
  duel(id: $duelId) {
    type
    price
    task {
      id
      name
      description
      role1
      role2
      sentence
    }
  }
}
    `;

/**
 * __useDuelTaskQuery__
 *
 * To run a query within a React component, call `useDuelTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useDuelTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDuelTaskQuery({
 *   variables: {
 *      duelId: // value for 'duelId'
 *   },
 * });
 */
export function useDuelTaskQuery(baseOptions: Apollo.QueryHookOptions<DuelTaskQuery, DuelTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DuelTaskQuery, DuelTaskQueryVariables>(DuelTaskDocument, options);
      }
export function useDuelTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DuelTaskQuery, DuelTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DuelTaskQuery, DuelTaskQueryVariables>(DuelTaskDocument, options);
        }
export type DuelTaskQueryHookResult = ReturnType<typeof useDuelTaskQuery>;
export type DuelTaskLazyQueryHookResult = ReturnType<typeof useDuelTaskLazyQuery>;
export type DuelTaskQueryResult = Apollo.QueryResult<DuelTaskQuery, DuelTaskQueryVariables>;
export const DuelsDocument = gql`
    query duels($email: String, $sort: String, $dates: String, $offset: Int, $limit: Int) {
  duels(email: $email, sort: $sort, dates: $dates, offset: $offset, limit: $limit) {
    duels {
      id
      type
      price
      access
      users {
        userId
        code
        role
      }
      date
      time
      status
      videoUrl
      task {
        id
        name
        description
        sentence
        role1
        role2
      }
      results {
        player1 {
          judge2
          judge3
          judge1
        }
        player2 {
          judge1
          judge2
          judge3
        }
      }
      agreements {
        player1
        player2
        votes {
          judge1
          judge2
          judge3
        }
      }
      characters {
        player1
        player2
      }
      userRole
      userVotes
      result
      streamKey
      organizerId
      group {
        id
        name
      }
      name
    }
    total
  }
}
    `;

/**
 * __useDuelsQuery__
 *
 * To run a query within a React component, call `useDuelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDuelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDuelsQuery({
 *   variables: {
 *      email: // value for 'email'
 *      sort: // value for 'sort'
 *      dates: // value for 'dates'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDuelsQuery(baseOptions?: Apollo.QueryHookOptions<DuelsQuery, DuelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DuelsQuery, DuelsQueryVariables>(DuelsDocument, options);
      }
export function useDuelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DuelsQuery, DuelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DuelsQuery, DuelsQueryVariables>(DuelsDocument, options);
        }
export type DuelsQueryHookResult = ReturnType<typeof useDuelsQuery>;
export type DuelsLazyQueryHookResult = ReturnType<typeof useDuelsLazyQuery>;
export type DuelsQueryResult = Apollo.QueryResult<DuelsQuery, DuelsQueryVariables>;
export const DuelsForReconnectDocument = gql`
    query duelsForReconnect {
  duelsForReconnect {
    id
    type
    taskId
    taskName
    date
    name
    organizerId
    price
    access
    group {
      id
      name
    }
    role
  }
}
    `;

/**
 * __useDuelsForReconnectQuery__
 *
 * To run a query within a React component, call `useDuelsForReconnectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDuelsForReconnectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDuelsForReconnectQuery({
 *   variables: {
 *   },
 * });
 */
export function useDuelsForReconnectQuery(baseOptions?: Apollo.QueryHookOptions<DuelsForReconnectQuery, DuelsForReconnectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DuelsForReconnectQuery, DuelsForReconnectQueryVariables>(DuelsForReconnectDocument, options);
      }
export function useDuelsForReconnectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DuelsForReconnectQuery, DuelsForReconnectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DuelsForReconnectQuery, DuelsForReconnectQueryVariables>(DuelsForReconnectDocument, options);
        }
export type DuelsForReconnectQueryHookResult = ReturnType<typeof useDuelsForReconnectQuery>;
export type DuelsForReconnectLazyQueryHookResult = ReturnType<typeof useDuelsForReconnectLazyQuery>;
export type DuelsForReconnectQueryResult = Apollo.QueryResult<DuelsForReconnectQuery, DuelsForReconnectQueryVariables>;
export const InstructionDocument = gql`
    query instruction {
  instruction {
    menu {
      id
      name
    }
    availableHtml
    instructionsForEdit {
      id
      html
      name
      author {
        id
        code
      }
      displayFor {
        forAll
        permission
        groups
      }
    }
  }
}
    `;

/**
 * __useInstructionQuery__
 *
 * To run a query within a React component, call `useInstructionQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstructionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstructionQuery({
 *   variables: {
 *   },
 * });
 */
export function useInstructionQuery(baseOptions?: Apollo.QueryHookOptions<InstructionQuery, InstructionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InstructionQuery, InstructionQueryVariables>(InstructionDocument, options);
      }
export function useInstructionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InstructionQuery, InstructionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InstructionQuery, InstructionQueryVariables>(InstructionDocument, options);
        }
export type InstructionQueryHookResult = ReturnType<typeof useInstructionQuery>;
export type InstructionLazyQueryHookResult = ReturnType<typeof useInstructionLazyQuery>;
export type InstructionQueryResult = Apollo.QueryResult<InstructionQuery, InstructionQueryVariables>;
export const UserStatisticsDocument = gql`
    query userStatistics($id: ID!) {
  userStatistics(id: $id) {
    negotiation {
      master {
        points
        total
      }
      player {
        total
        points
        defeat
        win
      }
      judge {
        total
        points
      }
    }
    express {
      master {
        points
        total
      }
      player {
        total
        points
        defeat
        win
      }
      judge {
        total
        points
      }
    }
    mini {
      master {
        points
        total
      }
      player {
        total
        points
        defeat
        win
      }
      judge {
        total
        points
      }
    }
    mini2 {
      master {
        points
        total
      }
      player {
        total
        points
        defeat
        win
      }
      judge {
        total
        points
      }
    }
    totalPoints
  }
}
    `;

/**
 * __useUserStatisticsQuery__
 *
 * To run a query within a React component, call `useUserStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStatisticsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserStatisticsQuery(baseOptions: Apollo.QueryHookOptions<UserStatisticsQuery, UserStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserStatisticsQuery, UserStatisticsQueryVariables>(UserStatisticsDocument, options);
      }
export function useUserStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserStatisticsQuery, UserStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserStatisticsQuery, UserStatisticsQueryVariables>(UserStatisticsDocument, options);
        }
export type UserStatisticsQueryHookResult = ReturnType<typeof useUserStatisticsQuery>;
export type UserStatisticsLazyQueryHookResult = ReturnType<typeof useUserStatisticsLazyQuery>;
export type UserStatisticsQueryResult = Apollo.QueryResult<UserStatisticsQuery, UserStatisticsQueryVariables>;
export const UserGroupsDocument = gql`
    query userGroups($userId: ID!) {
  userGroups(userId: $userId) {
    id
    name
    dateFrom
    dateTo
    isActive
  }
}
    `;

/**
 * __useUserGroupsQuery__
 *
 * To run a query within a React component, call `useUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserGroupsQuery(baseOptions: Apollo.QueryHookOptions<UserGroupsQuery, UserGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupsQuery, UserGroupsQueryVariables>(UserGroupsDocument, options);
      }
export function useUserGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupsQuery, UserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupsQuery, UserGroupsQueryVariables>(UserGroupsDocument, options);
        }
export type UserGroupsQueryHookResult = ReturnType<typeof useUserGroupsQuery>;
export type UserGroupsLazyQueryHookResult = ReturnType<typeof useUserGroupsLazyQuery>;
export type UserGroupsQueryResult = Apollo.QueryResult<UserGroupsQuery, UserGroupsQueryVariables>;
export const DuelsInfoDocument = gql`
    query duelsInfo {
  duelsInfo {
    id
    text1
    text2
    text3
    text4
  }
}
    `;

/**
 * __useDuelsInfoQuery__
 *
 * To run a query within a React component, call `useDuelsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDuelsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDuelsInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useDuelsInfoQuery(baseOptions?: Apollo.QueryHookOptions<DuelsInfoQuery, DuelsInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DuelsInfoQuery, DuelsInfoQueryVariables>(DuelsInfoDocument, options);
      }
export function useDuelsInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DuelsInfoQuery, DuelsInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DuelsInfoQuery, DuelsInfoQueryVariables>(DuelsInfoDocument, options);
        }
export type DuelsInfoQueryHookResult = ReturnType<typeof useDuelsInfoQuery>;
export type DuelsInfoLazyQueryHookResult = ReturnType<typeof useDuelsInfoLazyQuery>;
export type DuelsInfoQueryResult = Apollo.QueryResult<DuelsInfoQuery, DuelsInfoQueryVariables>;
export const SettingsDocument = gql`
    query settings {
  settings {
    id
    duelPrice {
      negotiation
      mini
      mini2
      express
    }
    training {
      months
      days
      diplomaPoint
      diplomaHonoursPoint
    }
    diplomaRu {
      generate
      title
      text1
      text2
      text3
      text4
    }
    diplomaEn {
      generate
      title
      text1
      text2
      text3
      text4
    }
  }
}
    `;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
      }
export function useSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
        }
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const DuelTypesPriceDocument = gql`
    query duelTypesPrice {
  duelTypesPrice {
    negotiation
    mini
    mini2
    express
  }
}
    `;

/**
 * __useDuelTypesPriceQuery__
 *
 * To run a query within a React component, call `useDuelTypesPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDuelTypesPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDuelTypesPriceQuery({
 *   variables: {
 *   },
 * });
 */
export function useDuelTypesPriceQuery(baseOptions?: Apollo.QueryHookOptions<DuelTypesPriceQuery, DuelTypesPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DuelTypesPriceQuery, DuelTypesPriceQueryVariables>(DuelTypesPriceDocument, options);
      }
export function useDuelTypesPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DuelTypesPriceQuery, DuelTypesPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DuelTypesPriceQuery, DuelTypesPriceQueryVariables>(DuelTypesPriceDocument, options);
        }
export type DuelTypesPriceQueryHookResult = ReturnType<typeof useDuelTypesPriceQuery>;
export type DuelTypesPriceLazyQueryHookResult = ReturnType<typeof useDuelTypesPriceLazyQuery>;
export type DuelTypesPriceQueryResult = Apollo.QueryResult<DuelTypesPriceQuery, DuelTypesPriceQueryVariables>;
export const PurchaseDocument = gql`
    query purchase($userId: ID!) {
  purchase(userId: $userId) {
    id
    userId
    memberships {
      id
      payDate
      startDate
      endDate
      duration
      price
    }
    trainings {
      id
      payDate
      startDate
      endDate
      diplomaNumber
      diplomaUrl
      diplomaEnNumber
      diplomaEnUrl
      points
      isChecked
      price
      duration {
        months
        days
      }
    }
    isShowEnglishName
    freeDuels
  }
}
    `;

/**
 * __usePurchaseQuery__
 *
 * To run a query within a React component, call `usePurchaseQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePurchaseQuery(baseOptions: Apollo.QueryHookOptions<PurchaseQuery, PurchaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseQuery, PurchaseQueryVariables>(PurchaseDocument, options);
      }
export function usePurchaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseQuery, PurchaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseQuery, PurchaseQueryVariables>(PurchaseDocument, options);
        }
export type PurchaseQueryHookResult = ReturnType<typeof usePurchaseQuery>;
export type PurchaseLazyQueryHookResult = ReturnType<typeof usePurchaseLazyQuery>;
export type PurchaseQueryResult = Apollo.QueryResult<PurchaseQuery, PurchaseQueryVariables>;
export const DiplomasDocument = gql`
    query diplomas($dateFrom: String!, $dateTo: String!, $name: String, $lastname: String) {
  diplomas(dateFrom: $dateFrom, dateTo: $dateTo, name: $name, lastname: $lastname) {
    number
    url
    userId
  }
}
    `;

/**
 * __useDiplomasQuery__
 *
 * To run a query within a React component, call `useDiplomasQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiplomasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiplomasQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      name: // value for 'name'
 *      lastname: // value for 'lastname'
 *   },
 * });
 */
export function useDiplomasQuery(baseOptions: Apollo.QueryHookOptions<DiplomasQuery, DiplomasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiplomasQuery, DiplomasQueryVariables>(DiplomasDocument, options);
      }
export function useDiplomasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiplomasQuery, DiplomasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiplomasQuery, DiplomasQueryVariables>(DiplomasDocument, options);
        }
export type DiplomasQueryHookResult = ReturnType<typeof useDiplomasQuery>;
export type DiplomasLazyQueryHookResult = ReturnType<typeof useDiplomasLazyQuery>;
export type DiplomasQueryResult = Apollo.QueryResult<DiplomasQuery, DiplomasQueryVariables>;
export const TransactionsDocument = gql`
    query transactions($userId: ID, $sort: String, $dateFrom: String!, $dateTo: String!, $code: String, $amountFrom: Int, $amountTo: Int, $comment: String) {
  transactions(
    userId: $userId
    sort: $sort
    dateFrom: $dateFrom
    dateTo: $dateTo
    code: $code
    amountFrom: $amountFrom
    amountTo: $amountTo
    comment: $comment
  ) {
    id
    date
    amount
    type
    comment
    userId
    code
    email
    isCompleted
  }
}
    `;

/**
 * __useTransactionsQuery__
 *
 * To run a query within a React component, call `useTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      sort: // value for 'sort'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      code: // value for 'code'
 *      amountFrom: // value for 'amountFrom'
 *      amountTo: // value for 'amountTo'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useTransactionsQuery(baseOptions: Apollo.QueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, options);
      }
export function useTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, options);
        }
export type TransactionsQueryHookResult = ReturnType<typeof useTransactionsQuery>;
export type TransactionsLazyQueryHookResult = ReturnType<typeof useTransactionsLazyQuery>;
export type TransactionsQueryResult = Apollo.QueryResult<TransactionsQuery, TransactionsQueryVariables>;
export const AllTypesDocument = gql`
    query allTypes {
  allTypes {
    id
    name
    duelTypes
    tasks
    periods {
      weekDay
      timeFrom
      timeTo
    }
    allTime
  }
}
    `;

/**
 * __useAllTypesQuery__
 *
 * To run a query within a React component, call `useAllTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTypesQuery(baseOptions?: Apollo.QueryHookOptions<AllTypesQuery, AllTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTypesQuery, AllTypesQueryVariables>(AllTypesDocument, options);
      }
export function useAllTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTypesQuery, AllTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTypesQuery, AllTypesQueryVariables>(AllTypesDocument, options);
        }
export type AllTypesQueryHookResult = ReturnType<typeof useAllTypesQuery>;
export type AllTypesLazyQueryHookResult = ReturnType<typeof useAllTypesLazyQuery>;
export type AllTypesQueryResult = Apollo.QueryResult<AllTypesQuery, AllTypesQueryVariables>;
export const AllRolesDocument = gql`
    query allRoles {
  allRoles {
    id
    name
  }
}
    `;

/**
 * __useAllRolesQuery__
 *
 * To run a query within a React component, call `useAllRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRolesQuery(baseOptions?: Apollo.QueryHookOptions<AllRolesQuery, AllRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllRolesQuery, AllRolesQueryVariables>(AllRolesDocument, options);
      }
export function useAllRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllRolesQuery, AllRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllRolesQuery, AllRolesQueryVariables>(AllRolesDocument, options);
        }
export type AllRolesQueryHookResult = ReturnType<typeof useAllRolesQuery>;
export type AllRolesLazyQueryHookResult = ReturnType<typeof useAllRolesLazyQuery>;
export type AllRolesQueryResult = Apollo.QueryResult<AllRolesQuery, AllRolesQueryVariables>;
export const AllUsersEmailsToComplaintDocument = gql`
    query allUsersEmailsToComplaint($userId: String!) {
  allUsersEmailsToComplaint(userId: $userId) {
    name
    email
  }
}
    `;

/**
 * __useAllUsersEmailsToComplaintQuery__
 *
 * To run a query within a React component, call `useAllUsersEmailsToComplaintQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersEmailsToComplaintQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersEmailsToComplaintQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAllUsersEmailsToComplaintQuery(baseOptions: Apollo.QueryHookOptions<AllUsersEmailsToComplaintQuery, AllUsersEmailsToComplaintQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUsersEmailsToComplaintQuery, AllUsersEmailsToComplaintQueryVariables>(AllUsersEmailsToComplaintDocument, options);
      }
export function useAllUsersEmailsToComplaintLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersEmailsToComplaintQuery, AllUsersEmailsToComplaintQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUsersEmailsToComplaintQuery, AllUsersEmailsToComplaintQueryVariables>(AllUsersEmailsToComplaintDocument, options);
        }
export type AllUsersEmailsToComplaintQueryHookResult = ReturnType<typeof useAllUsersEmailsToComplaintQuery>;
export type AllUsersEmailsToComplaintLazyQueryHookResult = ReturnType<typeof useAllUsersEmailsToComplaintLazyQuery>;
export type AllUsersEmailsToComplaintQueryResult = Apollo.QueryResult<AllUsersEmailsToComplaintQuery, AllUsersEmailsToComplaintQueryVariables>;