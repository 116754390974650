import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { userVar } from "../../cache/cache";
import { useReactiveVar } from "@apollo/client";
import { useDeleteGroupMutation } from "../../types/graphql";

import "./styles.css";
import { GROUPS } from "../../graphql/queries";

const Button = React.lazy(() => import("../../components/common/Button"));

interface LocationState {
    groupId: string;
}

const DeleteUserFromGroup: React.FC = () => {
    const [wasSent] = React.useState<boolean>(false);
    const [isBtnDisabled, setIsBtnDisabled] = React.useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const user = useReactiveVar(userVar);
    const locationState = location.state as LocationState;

    const [removeGroup] = useDeleteGroupMutation({
        onCompleted: (data) => {
            setIsBtnDisabled(false);
            navigate(location.pathname + location.search);
        },
        onError: (e) => {
            setIsBtnDisabled(false);
            console.log("Complaint error", e);
        },
        refetchQueries: () => {
            return [
                {
                    query: GROUPS,
                },
            ];
        },
    });

    const deleteGroup = () => {
        setIsBtnDisabled(true);
        removeGroup({
            variables: {
                id: locationState?.groupId,
            },
        });
    };

    return (
        <div className="delete-group">
            {user && (
                <>
                    <div className="delete-group__title">Вы уверены, что хотите удалить этого пользователя?</div>
                    <div className="delete-group__divider"></div>
                    <div className="delete-group__button">
                        {wasSent ? (
                            <Button
                                text="Закрыть"
                                onClick={() => navigate(location.pathname + location.search)}
                                mode="green"
                            />
                        ) : (
                            <Button
                                text="Удалить группу"
                                onClick={deleteGroup}
                                mode="burgundy"
                                disabled={isBtnDisabled}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default DeleteUserFromGroup;
