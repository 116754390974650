import React from "react";
import "./styles.css";

const CookiesPolicy: React.FC = () => {
    return (
        <div className="task-info">
            <>
                <div className="task-info__title">{"Использование cookies"}</div>
                <div className="task-info__roles">
                    <div className="scroll">
                        <p>
                            <span className="boldText">Общие положения</span>
                        </p>
                        <p>
                            Настоящий документ содержит условия и информацию, которые поясняют, как применяется политика
                            использования файлов cookie на сайте Таллиннской школы менеджеров и какие файлы cookie
                            ис-пользуются. С условиями приватности предприятия вы можете ознакомиться здесь.
                        </p>
                        <p>
                            Обращаем ваше внимание на то, что настоящая политика использования файлов cookie применяется
                            вне зависимости от типа используемого устройства, то есть не имеет значения то
                            обстоятельство, по-сещаете ли вы сайт через компьютер, планшет, смартфон или
                            смарт-телевизор.
                        </p>

                        <p>
                            <span className="boldText">Что такое файлы cookie и какую пользу они вам приносят?</span>
                        </p>
                        <p>
                            Файлы cookie – это маленькие текстовые файлы (менее 1 Кб), которые сохраняются в браузере
                            (например, Internet Explorer, Google Chrome, Firefox, Safari или Opera) вашего устройства
                            (например, компьютер, мобильный телефон, планшет), когда вы посещаете сайт. Они широко
                            используются для того, чтобы сайты работали и функционировали лучше и эффективнее, и
                            помогают персонализировать и обогащать ваш опыт просмотра сайтов, отображая то содержание,
                            которое с большей вероятностью необходимо и интересно вам. На сегодняшний день в случае
                            большинства сайтов использование файлов cookie стандартно. При отказе от использования
                            файлов cookie работа части функций сайта может ухудшиться.
                        </p>
                        <p>
                            Файлы cookie обычно хранятся во временных (TEMP) файлах, которые удаляются по завершении
                            сес-сии в браузере или сохраняются на жестком диске устройства, вне зависимости от того,
                            истек ли срок действия файлов cookie или нет. Более подробную информацию о файлах cookie вы
                            можете найти по следующему адресу: http://www.aki.ee/et/kupsised.
                        </p>

                        <ol>
                            <p>
                                <span className="boldText">Файлы cookie помогают нам:</span>
                            </p>

                            <li>обеспечивать функционирование нашего сайта в соответствии с вашими ожиданиями;</li>
                            <li>
                                при необходимости вспоминать ваши настройки и выборы во время посещений и между ни-ми;
                            </li>
                            <li>повышать скорость работы / безопасность сайта;</li>
                            <li>постоянно совершенствовать свой сайт для вас;</li>
                            <li>
                                повышать эффективность маркетинга (помогая нам оказывать услугу по той цене, которую мы
                                предлагаем).
                            </li>
                        </ol>

                        <ol>
                            <p>
                                <span className="boldText">Какие файлы cookie мы используем?</span>
                            </p>
                            Мы используем файлы cookie двух типов, которые известны как сессионные файлы cookie и
                            постоян-ные файлы cookie. Это те файлы cookie, которые используем мы и/или используют третьи
                            стороны.
                            <br /> <br />
                        </ol>

                        <ol>
                            <p>
                                <span className="boldText">Типы используемых нами файлов cookie:</span>
                            </p>
                            <li>
                                <span className="boldText">Сессионные файлы cookie</span> используются каждый раз, когда
                                вы посещаете наш сайт. Мы ис-пользуем такого рода файлы cookie для того, чтобы
                                ассоциировать ваши действия на нашем сайте, и каждый сессионный файл cookie действует
                                только во время сессии в браузере, после чего срок его действия истекает. Любая сессия в
                                браузере начинается тогда, когда вы откры-ваете свой браузер, и заканчивается тогда,
                                когда вы его закрываете. После посещения сайта все сессионные файлы cookie удаляются.
                            </li>
                            <li>
                                <span className="boldText">Постоянные файлы cookie</span> позволяют нам запоминать ваши
                                предпочтения и действия на сай-те (или между сайтами). У них более продолжительный срок
                                жизни, нежели у сессионных файлов cookie, и они действуют в течение указанного в файле
                                cookie определенного срока, который у разных файлов cookie разный. Такого рода файлы
                                cookie не удаляются при закры-тии браузера, а сохраняются на вашем устройстве. Эти файлы
                                cookie активируются каждый раз, когда вы посещаете сайт, создавший конкретный постоянный
                                файл cookie.
                            </li>
                        </ol>

                        <ol>
                            <p>
                                <span className="boldText">Как мы классифицируем файлы cookie?</span>
                            </p>
                            Для обеспечения большей ясности по части файлов cookie мы классифицировали их следующим
                            об-разом: <br /> <br />
                            <li>
                                <span className="boldText">
                                    Строго необходимые файлы cookie и функциональные файлы cookie.
                                </span>{" "}
                                Эти файлы cookie безусловно необходимы, чтобы позволять посетителю перемещаться по
                                разным страницам и ис-пользовать такие функции сайта как запоминание введенной в формы
                                информации тогда, ко-гда посетитель на протяжении сессии в браузере посещает разные
                                страницы. Без строго необ-ходимых файлов cookie определенные услуги сайта могут быть
                                недоступны, и сайт может функционировать недостаточно слаженно. Помимо этого,
                                функциональные файлы cookie поз-воляют запоминать сделанные вами выборы на сайте и
                                предлагать усовершенствованные и более персонализированные функции. Эти файлы cookie не
                                собирают никакую информацию в маркетинговых целях или для того, чтобы запоминать историю
                                ваших посещений в интернете.
                            </li>
                            <li>
                                <span className="boldText">Аналитические, или производительные, файлы cookie.</span>{" "}
                                Производительные файлы cookie со-бирают информацию о пользовании сайтом и помогают нам
                                улучшать способ функциониро-вания сайта. Например, производительные файлы cookie могут
                                показывать, какие страницы чаще всего посещаются, записывать любые трудности, возникшие
                                при посещении сайта, и по-казывать, эффективна ли опубликованная на сайте реклама или
                                нет. Производительные фай-лы cookie не собирают информацию, устанавливающую личность
                                посетителя, и вся собранная с помощью этих файлов cookie информация объединена и
                                анонимна.
                            </li>
                            <li>
                                <span className="boldText">Целевые, или рекламные, файлы cookie.</span> Целевые, или
                                рекламные, файлы cookie используют-ся для отображения более соответствующей вам и вашим
                                интересам рекламы или для того, чтобы ограничивать количество показов вам одной и той же
                                рекламы на сайте. Такого рода файлы cookie используются также для того, чтобы помогать
                                оценивать эффективность реклам-ной кампании и запоминать, что вы посмотрели на сайте.
                            </li>
                        </ol>

                        <p>
                            <span className="boldText">Функции третьих сторон</span>
                        </p>
                        <p>
                            Третьи стороны, публикующие рекламу, и прочие организации могут использовать свои файлы
                            cookie или прочие технологии, чтобы собирать информацию о содержании нашего сайта и/или об
                            их рекла-ме, по которой вы кликнули. Они могут использовать такого рода информацию для того,
                            чтобы пока-зывать рекламу, которую они посчитают наиболее соответствующей вашим интересам,
                            опираясь на просмотренное вами содержание сайтов. Третьи стороны, публикующие рекламу, могут
                            использовать эту информацию также для того, чтобы оценивать эффективность своей рекламы.
                        </p>
                        <p>
                            Мы не имеем доступа ко всем файлам cookie, которые третьи стороны установили на нашем сайте,
                            и не контролируем эти файлы cookie; их использование регулируется условиями приватности и
                            полити-кой использования файлов cookie самих третьих сторон. Поэтому рекомендуем вам
                            ознакомиться от-дельно с условиями приватности всех третьих сторон.
                        </p>

                        <p>
                            <span className="boldText">Как управлять файлами cookie?</span>
                        </p>
                        <p>
                            Если вы не настроили свой браузер таким образом, что он отказывается от использования всех
                            фай-лов cookie или определенного типа файлов cookie, то файлы cookie используются сразу, как
                            только вы заходите на сайт. Когда вы посещаете наш сайт в первый раз, для вас отображается
                            информация об использовании файлов cookie. Для дачи согласия на использование файлов cookie
                            следует кликнуть по кнопке с надписью «Согласен(на)». Если вы не кликните по кнопке с
                            надписью «Согласен(на)» или кликните по кнопке «Не согласен(на)», то это будет означать, что
                            вы не дали согласие на использова-ние файлов cookie. В таком случае файлы cookie не будут
                            устанавливаться – исключением являются те файлы cookie, которые необходимы для
                            функционирования наших сайтов, то есть строго необходи-мые файлы cookie.
                        </p>
                        <p>
                            Использование строго необходимых и функциональных файлов cookie сопутствует пользованию
                            нашим сайтом. В случае отказа от этих файлов cookie мы не можем предсказать, как будет
                            работать сайт при его посещении. Вы можете контролировать использование аналитических файлов
                            cookie, целевых файлов cookie или рекламных файлов cookie, адаптируя настройки своего
                            браузера.
                        </p>
                        <p>
                            Большинство браузеров позволяют вам контролировать файлы cookie через свои предпочтения
                            настроек. Если вы предпочитаете не получать файлы cookie, можете настроить свой браузер
                            таким об-разом, чтобы он отказывался от использования всех файлов cookie или отправлял
                            предупреждение об использовании файла cookie. Мы не можем удалять файлы cookie,
                            установленные в браузер ваше-го устройства. Если вы хотите удалить файлы cookie из своего
                            устройства, то для этого у разных брау-зеров есть вспомогательные страницы с информацией о
                            том, как удалить файлы cookie. Если вы поль-зуетесь несколькими браузерами, то не забудьте
                            удалить файлы cookie из всех них. Дополнительную информацию о том, как настраивать связанные
                            с файлами cookie предпочтения в самых популярных браузерах, вы найдете здесь:
                        </p>

                        <p>
                            <a
                                className="link"
                                target="_blank"
                                href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                                rel="noreferrer"
                            >
                                Internet Explorer
                            </a>{" "}
                            ,
                            <a
                                className="link"
                                target="_blank"
                                href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies"
                                rel="noreferrer"
                            >
                                Mozilla Firefox
                            </a>{" "}
                            ,
                            <a
                                className="link"
                                target="_blank"
                                href="https://support.google.com/chrome/answer/95647?hl=en"
                                rel="noreferrer"
                            >
                                Google Chrome
                            </a>{" "}
                            ,
                            <a
                                className="link"
                                target="_blank"
                                href="https://support.apple.com/kb/PH5042?locale=en_US"
                                rel="noreferrer"
                            >
                                Safari
                            </a>{" "}
                            ,
                            <a
                                className="link"
                                target="_blank"
                                href="https://support.apple.com/en-us/HT201265"
                                rel="noreferrer"
                            >
                                IOS
                            </a>{" "}
                        </p>

                        <p>
                            Обращаем ваше внимание на то обстоятельство, что в случае удаления файлов cookie или отказа
                            от их использования вы можете потерять доступ к определенным частям или функциям нашего
                            сайта. Настройка использования файлов cookie повлияет также на другие посещаемые сайты.
                        </p>

                        <p>
                            Если вы хотите получить более подробную информацию об используемых на сайте файлах cookie,
                            то свяжитесь с нами по следующему адресу электронной почты: .
                            <a
                                className="link"
                                target="_blank"
                                href="mailto:school@tarassov.ee"
                                rel="noreferrer"
                            >
                                school@tarassov.ee
                            </a>{" "}
                        </p>

                        <p className="boldText">Действие и изменения Политики использования файлов cookie</p>

                        <p>
                            Мы имеем право в любой момент в одностороннем порядке внести изменения в Политику
                            использо-вания файлов cookie в соответствии с применяемым правом.
                        </p>

                        <p>Настоящая Политика использования файлов cookie принята и опубликована 01.03.2019 г.</p>
                    </div>
                </div>
            </>
        </div>
    );
};

export default CookiesPolicy;
